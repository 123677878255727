<template>
  <div class="sales-forecast">
    <div class="page-content">
      <template v-if="isLoading">
        <LoadingSpinner />
      </template>
      
      <template v-else>
        <header class="header-section">
          <div class="header-with-info">
            <h1>Salgsforecast</h1>
            <div class="info-container">
              <img 
                src="@/assets/images/icons/Information.svg" 
                alt="Information" 
                class="info-icon"
              />
              <div class="info-tooltip">
                Forventet omsætning for de kommende 7 dage
              </div>
            </div>
          </div>
        </header>

        <section v-if="userBusinesses.length > 0">
          <div 
            v-for="business in userBusinesses" 
            :key="business.id"
            class="forecast-dashboard"
          >
            <div class="business-header" @click="toggleBusiness(business.id)">
              <div class="business-summary">
                <h2>{{ business.businessData?.businessName }}</h2>
                <div class="vertical-divider"></div>
                <div class="summary-metrics">
                  <span>Forventet omsætning: 
                    <span :style="{ color: business.businessData?.colorScheme?.primary || '#005432', fontWeight: 'bold' }">
                      {{ formatCurrency(getWeeklyTotal(business, 'revenue')) }}
                    </span>
                  </span>
                  <span>Nuværende lønudgifter: 
                    <span :style="{ color: business.businessData?.colorScheme?.primary || '#005432', fontWeight: 'bold' }">
                      {{ formatCurrency(getWeeklyTotal(business, 'expenses')) }}
                    </span>
                  </span>
                  <span>Estimeret lønprocent: 
                    <span :style="{ color: isWeeklySalaryPercentageHigh(business) ? '#FF9999' : '#005432', fontWeight: 'bold' }">
                      {{ calculateWeeklySalaryPercentage(business) }}%
                    </span>
                    <span class="metric-target">(mål: {{ getCurrentMonthSalaryTarget(business) }}%)</span>
                  </span>
                  <span>Forskel: 
                    <span :style="{ ...getDifferenceBackgroundColor(((getWeeklyTotal(business, 'revenue') * getCurrentMonthSalaryTarget(business)) / 100) - getWeeklyTotal(business, 'expenses'))}">
                      {{ formatCurrency(((getWeeklyTotal(business, 'revenue') * getCurrentMonthSalaryTarget(business)) / 100) - getWeeklyTotal(business, 'expenses')) }}
                    </span>
                  </span>
                </div>
              </div>
              <span class="expand-indicator" :class="{ 'expanded': expandedBusinesses[business.id] }">
                <i class="fas fa-chevron-down"></i>
              </span>
            </div>

            <div v-show="expandedBusinesses[business.id]" class="business-details">
              <div class="horizontal-divider"></div>
              
              <div class="forecast-table">
                <h4>SALGS- OG LØNPROCENT FORECAST</h4>
                <table>
                  <thead>
                    <tr>
                      <th></th>
                      <th v-for="day in getDailyForecast(business)" :key="day.date">
                        {{ formatDate(day.date) }}
                        <br>
                        <img 
                          v-if="day.weatherSymbol"
                          :src="require(`@/assets/images/weather_icons/${day.weatherSymbol}.svg`)"
                          :alt="day.weatherSymbol"
                          :title="day.weatherDescription"
                          style="width: 24px; height: 24px; margin: 4px 0;"
                        >
                        <br>
                        {{ formatWeekday(day.date) }}
                        <br>
                        <span v-if="day.holidayName" class="holiday-tag">{{ day.holidayName }}</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <!-- Forventet omsætning -->
                    <tr class="highlight-row">
                      <td>Forventet omsætning</td>
                      <td v-for="day in getDailyForecast(business)" :key="day.date">
                        <template v-if="day.isClosed">Lukket</template>
                        <template v-else>{{ formatCurrency(day.revenue) }}</template>
                      </td>
                    </tr>

                    <!-- Anbefalede lønudgifter -->
                    <tr>
                      <td>Anbefalede lønudgifter</td>
                      <td v-for="day in getDailyForecast(business)" :key="day.date">
                        <template v-if="day.isClosed">-</template>
                        <template v-else>
                          {{ formatCurrency((day.revenue * getCurrentMonthSalaryTarget(business)) / 100) }}
                        </template>
                      </td>
                    </tr>

                    <!-- Faktiske lønudgifter -->
                    <tr>
                      <td>Faktiske lønudgifter</td>
                      <td v-for="day in getDailyForecast(business)" :key="day.date">
                        <template v-if="day.isClosed">-</template>
                        <template v-else>{{ formatCurrency(day.expenses) }}</template>
                      </td>
                    </tr>

                    <!-- Forskel -->
                    <tr class="highlight-row">
                      <td>Forskel</td>
                      <td v-for="day in getDailyForecast(business)" :key="day.date">
                        <template v-if="day.isClosed">-</template>
                        <template v-else>
                          <div 
                            class="difference-cell"
                            :style="getDifferenceBackgroundColor(((day.revenue * getCurrentMonthSalaryTarget(business)) / 100) - day.expenses)"
                          >
                            {{ formatCurrency(((day.revenue * getCurrentMonthSalaryTarget(business)) / 100) - day.expenses) }}
                          </div>
                        </template>
                      </td>
                    </tr>

                    <!-- Forventet lønprocent -->
                    <tr>
                      <td>Nuv. forventet lønprocent</td>
                      <td v-for="day in getDailyForecast(business)" :key="day.date">
                        <template v-if="day.isClosed">-</template>
                        <template v-else>
                          {{ (day.expenses / day.revenue * 100).toFixed(1) }}%
                        </template>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="sales-chart">
                <h4>OMSÆTNING OG UDGIFTER</h4>
                <v-chart 
                  class="chart" 
                  :option="getChartOption(business)" 
                  autoresize
                />
              </div>

              <div class="forecast-performance-link">
                <router-link 
                  :to="`/business/${encodeURIComponent(business.businessData?.businessName)}/forecast-performance`"
                  style="text-decoration: none;"
                >
                  <TextWithArrowButton text="Historisk forecast performance" />
                </router-link>
              </div>
            </div>
          </div>
        </section>

        <section v-else>
          <p>Ingen restauranter tilknyttet</p>
        </section>
      </template>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import VChart from 'vue-echarts';
import { use } from 'echarts/core';
import { LineChart } from 'echarts/charts';
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import TextWithArrowButton from '@/components/TextWithArrowButton.vue';

use([
  CanvasRenderer,
  LineChart,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent
]);

export default {
  name: 'SalesForecast',
  components: {
    VChart,
    TextWithArrowButton
  },
  setup() {
    const store = useStore();

    const userBusinesses = computed(() => {
      const businesses = [];
      store.state.organizations.forEach(org => {
        org.businesses.forEach(business => {
          businesses.push({
            id: business.name,
            businessData: business.data,
          });
        });
      });
      return businesses;
    });

    const formatDate = (dateString) => {
      return new Date(dateString).toLocaleDateString('da-DK', {
        day: 'numeric',
        month: 'short'
      });
    };

    const formatWeekday = (dateString) => {
      return new Date(dateString).toLocaleDateString('da-DK', {
        weekday: 'long'
      });
    };

    const formatCurrency = (amount) => {
      return new Intl.NumberFormat('da-DK', {
        style: 'currency',
        currency: 'DKK',
        maximumFractionDigits: 0
      }).format(amount);
    };

    const getNextWeekForecast = (forecastData) => {
      if (!forecastData) return [];
      
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      
      return Object.entries(forecastData)
        .map(([date, value]) => ({ date, value }))
        .filter(forecast => {
          const forecastDate = new Date(forecast.date);
          return forecastDate >= today;
        })
        .sort((a, b) => new Date(a.date) - new Date(b.date))
        .slice(0, 7);
    };

    const isClosedDay = (business, date) => {
      const closedDays = business.businessData?.closedDays;
      if (!closedDays) return false;

      const dateObj = new Date(date);
      const mmdd = `${String(dateObj.getMonth() + 1).padStart(2, '0')}-${String(dateObj.getDate()).padStart(2, '0')}`;
      
      // Get weekday in lowercase
      const weekdays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
      const weekday = weekdays[dateObj.getDay()];
      
      // Check common dates
      if (closedDays.commonDates?.includes(mmdd)) return true;
      
      // Check specific dates
      if (closedDays.specificDates?.includes(date)) return true;
      
      // Check weekly closures
      if (closedDays.weeklyClosures?.includes(weekday)) return true;
      
      return false;
    };

    const getDailyForecast = (business) => {
      const comingWeek = business.businessData?.comingWeek || {};
      
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      
      return Object.entries(comingWeek)
        .map(([date, dayData]) => ({
          date,
          revenue: dayData.arimaTs || 0,
          expenses: dayData.salaryExpense || 0,
          holidayName: dayData.holidayName,
          weatherSymbol: dayData.weatherSymbol,
          weatherDescription: dayData.weatherDescription,
          isClosed: isClosedDay(business, date)
        }))
        .filter(day => {
          const forecastDate = new Date(day.date);
          return forecastDate >= today;
        })
        .sort((a, b) => new Date(a.date) - new Date(b.date))
        .slice(0, 7)
        .map(day => ({
          ...day,
          revenue: day.isClosed ? 0 : day.revenue,
          expenses: day.isClosed ? 0 : day.expenses
        }));
    };

    const getChartOption = (business) => {
      if (!business?.businessData) return {};
      
      const forecasts = getDailyForecast(business);
      if (!forecasts?.length) return {};
      
      const primaryColor = business.businessData?.colorScheme?.primary || '#005432';
      
      return {
        tooltip: {
          trigger: 'axis',
          axisPointer: { 
            type: 'line',
            lineStyle: {
              type: 'solid'
            }
          },
          formatter: (params) => {
            const day = forecasts.find(f => f.date === params[0].name);
            if (day.isClosed) {
              return `${formatDate(day.date)}<br/>
                      ${day.holidayName || 'Lukket'}<br/>
                      Virksomheden er lukket denne dag`;
            }
            const revenue = params[0].value;
            const expenses = params[1].value;
            const percentage = revenue ? ((expenses / revenue) * 100).toFixed(1) : 0;
            
            return `${formatDate(day.date)}<br/>
                    ${day.holidayName ? day.holidayName + '<br/>' : ''}
                    Omsætning: ${formatCurrency(revenue)}<br/>
                    Udgifter: ${formatCurrency(expenses)}<br/>
                    Lønprocent: ${percentage}%`;
          }
        },
        legend: {
          data: ['Omsætning', 'Udgifter'],
          textStyle: {
            color: '#005432'
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: forecasts.map(f => f.date),
          axisLabel: {
            formatter: (value) => formatDate(value),
            color: '#005432'
          },
          boundaryGap: false
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: (value) => formatCurrency(value),
            color: '#005432'
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: 'rgba(255, 255, 255, 0.7)',
              type: 'solid'
            }
          }
        },
        series: [
          {
            name: 'Omsætning',
            type: 'line',
            smooth: true,
            symbolSize: 8,
            lineStyle: {
              width: 3
            },
            data: forecasts.map(f => ({
              value: f.revenue,
              itemStyle: {
                color: f.isClosed ? '#e0e0e0' : primaryColor
              }
            })),
            itemStyle: {
              color: primaryColor
            }
          },
          {
            name: 'Udgifter',
            type: 'line',
            smooth: true,
            symbolSize: 8,
            lineStyle: {
              width: 3
            },
            data: forecasts.map(f => ({
              value: f.expenses,
              itemStyle: {
                color: f.isClosed ? '#e0e0e0' : '#FF9999'
              }
            })),
            itemStyle: {
              color: '#FF9999'
            }
          }
        ]
      };
    };

    const getCurrentMonth = () => {
      return new Date().getMonth() + 1;
    };

    const getCurrentMonthSalaryTarget = (business) => {
      const currentMonth = getCurrentMonth().toString().padStart(2, '0');
      return business.businessData?.salaryTarget?.[currentMonth] || 0;
    };

    const getWeeklyTotal = (business, type) => {
      const forecasts = getDailyForecast(business);
      return forecasts.reduce((sum, day) => 
        sum + (type === 'revenue' ? day.revenue : day.expenses), 
        0
      );
    };

    const calculateWeeklySalaryPercentage = (business) => {
      const totalRevenue = getWeeklyTotal(business, 'revenue');
      const totalExpenses = getWeeklyTotal(business, 'expenses');
      return totalRevenue ? ((totalExpenses / totalRevenue) * 100).toFixed(1) : 0;
    };

    const isWeeklySalaryPercentageHigh = (business) => {
      const percentage = parseFloat(calculateWeeklySalaryPercentage(business));
      const target = getCurrentMonthSalaryTarget(business);
      return percentage > target;
    };

    const calculateDailySalaryPercentage = (day) => {
      return day.revenue ? ((day.expenses / day.revenue) * 100).toFixed(1) : 0;
    };

    const isSalaryPercentageHigh = (day, business) => {
      const percentage = parseFloat(calculateDailySalaryPercentage(day));
      const target = getCurrentMonthSalaryTarget(business);
      return percentage > target;
    };

    const getDifferenceColor = (difference) => {
      // Convert difference to a percentage for color intensity (-100 to 100)
      const intensity = Math.min(Math.abs(difference) / 5000 * 100, 100);
      return {
        color: difference >= 0 
          ? `rgb(0, ${Math.round(84 + (intensity * 1.71))}, 0)` // Greens get brighter
          : `rgb(${Math.round(155 + intensity)}, 0, 0)` // Reds get darker
      };
    };

    const getDifferenceBackgroundColor = (difference) => {
      const intensity = Math.min(Math.abs(difference) / 5000 * 100, 100);
      return {
        backgroundColor: difference >= 0 
          ? `rgba(0, 84, 50, ${intensity * 0.008})` // Lighter to darker green
          : `rgba(255, 153, 153, ${intensity * 0.008})`, // Lighter to darker red
        color: difference >= 0 
          ? intensity > 50 ? '#ffffff' : '#005432' // White text for dark green, dark green for light green
          : intensity > 50 ? '#ffffff' : '#FF9999', // White text for dark red, red for light red
        padding: '0.5rem',
        borderRadius: '4px',
      };
    };

    const expandedBusinesses = ref({});

    const toggleBusiness = (businessId) => {
      expandedBusinesses.value[businessId] = !expandedBusinesses.value[businessId];
    };

    return {
      userBusinesses,
      formatDate,
      formatWeekday,
      formatCurrency,
      getNextWeekForecast,
      getChartOption,
      getWeeklyTotal,
      calculateWeeklySalaryPercentage,
      isWeeklySalaryPercentageHigh,
      getCurrentMonthSalaryTarget,
      getDailyForecast,
      calculateDailySalaryPercentage,
      isSalaryPercentageHigh,
      getDifferenceColor,
      getDifferenceBackgroundColor,
      expandedBusinesses,
      toggleBusiness,
    };
  }
};
</script>

<style scoped>
.sales-forecast {
  --card-padding: clamp(0.75rem, 2vw, 1.5rem);
  --card-radius: 8px;
  }


.sales-forecast {
  padding: 0rem 2rem 2rem 0rem;
  background-color: var(--background-color);
}

.page-content {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 1rem;
}

.header-section {
  text-align: center;
  margin-bottom: 2rem;
}

.header-with-info {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.header-section h1 {
  font-family: 'Darker Grotesque', sans-serif;
  font-size: 2.5rem;
  color: var(--primary-text-color);
  margin: 0;
  display: inline-block;
}

.forecast-dashboard {
  background: white;
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 2rem;
  box-shadow: 0 2px 4px var(--shadow-color);
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
}

.forecast-dashboard h2 {
  color: var(--primary-text-color);
  margin-bottom: 1.5rem;
  font-family: 'Darker Grotesque', sans-serif;
}

.forecast-chart {
  margin-bottom: 2rem;
  min-height: 350px;
}

.forecast-chart h3 {
  color: var(--primary-text-color);
  margin-bottom: 1rem;
  font-family: 'Darker Grotesque', sans-serif;
}

.chart {
  height: 300px;
  width: 100%;
  min-height: 300px;
}

.forecast-table {
  background: white;
  border-radius: 8px;
  padding: 1rem;
  width: 100%;
  overflow-x: auto;
  max-width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  margin: 1rem 0;
  margin-bottom: 3rem;
}

.forecast-table table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
  max-width: 100%;
}

.forecast-table th {
  min-width: 90px;
  max-width: 120px;
  text-align: center;
  vertical-align: top;
  padding: 1rem 0.75rem;
  font-size: clamp(0.85rem, 1.5vw, 1rem);
  white-space: nowrap;
  border-bottom: 2px solid var(--background-color);
}

.forecast-table td {
  min-width: 90px;
  max-width: 120px;
  text-align: center;
  padding: 0.75rem;
  font-size: clamp(1.3rem, 1.5vw, 1.1rem);
  border-bottom: 1px solid var(--background-color);
}

.forecast-table tr:last-child td {
  border-bottom: none;
}

.forecast-table tr td:first-child,
.forecast-table tr th:first-child {
  position: sticky;
  left: 0;
  background: white;
  z-index: 1;
  text-align: left;
  font-weight: 600;
  min-width: 130px;
  max-width: 150px;
  padding-left: 1rem;
}

.forecast-table tr:hover td {
  background-color: var(--background-color);
}

.highlight-row td {
  background-color: var(--background-color);
  font-weight: 500;
}

/* Add responsive breakpoints */
@media (max-width: 768px) {
  .forecast-table th,
  .forecast-table td {
    min-width: 80px;
    padding: 0.5rem 0.25rem;
  }
  
  .forecast-table tr td:first-child,
  .forecast-table tr th:first-child {
    min-width: 110px;
  }
}

@media (max-width: 480px) {
  .forecast-table th,
  .forecast-table td {
    min-width: 70px;
    font-size: clamp(0.7rem, 1.2vw, 0.8rem);
  }
  
  .forecast-table tr td:first-child,
  .forecast-table tr th:first-child {
    min-width: 100px;
  }
}

th, td {
  padding: 0.75rem;
  text-align: left;
  border-bottom: 1px solid var(--light-grey);
}

th {
  color: var(--primary-text-color);
  font-weight: 600;
}

tr:hover {
  background-color: var(--background-color);
}

.key-metrics {
  display: grid;
  grid-template-columns: repeat(3, minmax(200px, 1fr));
  gap: 1rem;
  padding: 0.5rem;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

.metric-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.metric-value {
  font-size: 1rem;
  font-weight: bold;
}

.metric-value.warning {
  color: #FF9999;
}

.status-indicator {
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.9rem;
}

.status-indicator.good {
  background-color: rgba(0, 84, 50, 0.1);
  color: var(--success-green);
}

.status-indicator.warning {
  background-color: rgba(255, 153, 153, 0.1);
  color: var(--error-red);
}

.metric-target {
  font-size: 0.8rem;
  color: var(--grey);
}

.holiday-tag {
  display: block;
  font-size: 0.8rem;
  padding: 0.2rem 0.5rem;
  margin-top: 0.25rem;
  background-color: #f0f0f0;
  border-radius: 4px;
  color: var(--grey);
}

.closed-tag {
  display: inline-block;
  font-size: 0.8rem;
  padding: 0.2rem 0.5rem;
  margin-left: 0.5rem;
  background-color: rgba(255, 153, 153, 0.1);
  border-radius: 4px;
  color: var(--error-red);
}

.status-indicator.closed {
  background-color: #f0f0f0;
  color: var(--grey);
}

.highlight-row {
  background-color: #f0f0f0;
}

.difference-cell {
  padding: 0.5rem;
  border-radius: 4px;
}

.highlight-row td {
  font-size: 1.4rem;
  font-weight: 500;
}

.weekly-overview {
  background: white;
  border-radius: 8px;
  padding: 0.75rem;
  margin-bottom: 1rem;
  width: 100%;
}

.key-metrics {
  display: grid;
  grid-template-columns: repeat(3, minmax(200px, 1fr));
  gap: 1rem;
  padding: 0.5rem;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

.metric-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0.5rem;
}

.metric-divider {
  display: none; /* Hide dividers in grid layout */
}

/* Table styles */
.forecast-table {
  width: 100%;
  overflow-x: auto;
}

.forecast-table table {
  width: 100%;
  min-width: 800px; /* Minimum width to prevent squishing */
}

.forecast-table tr td:first-child,
.forecast-table tr th:first-child {
  position: sticky;
  left: 0;
  background: white;
  z-index: 1;
  text-align: left;
  font-weight: 600;
  width: 200px;
  white-space: normal; /* Allow text to wrap */
  word-wrap: break-word;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .key-metrics {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }

  .metric-label {
    font-size: 0.85rem;
  }

  .metric-value {
    font-size: 1rem;
  }

  .forecast-table tr td:first-child,
  .forecast-table tr th:first-child {
    width: 180px;
  }
}

@media (max-width: 768px) {
  .key-metrics {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }

  .metric-label {
    font-size: 0.8rem;
  }

  .metric-value {
    font-size: 0.95rem;
  }

  .forecast-table tr td:first-child,
  .forecast-table tr th:first-child {
    width: 150px;
  }
}

@media (max-width: 480px) {
  .key-metrics {
    grid-template-columns: 1fr;
  }

  .forecast-table tr td:first-child,
  .forecast-table tr th:first-child {
    width: 120px;
    font-size: 0.8rem;
  }
}

.business-header {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  gap: 1rem;
}

.business-header:hover {
  background-color: var(--background-color);
}

.business-summary {
  display: flex;
  align-items: center;
  flex: 1;
  min-width: 0;
}

.business-summary h2 {
  font-family: 'Darker Grotesque', sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  color: var(--primary-text-color);
  margin: 0;
  width: 7rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.vertical-divider {
  width: 1px;
  height: 2.5rem;
  background-color: var(--primary-text-color);
  margin: 0 2rem;
  flex-shrink: 0;
}

.expand-indicator {
  margin-left: auto;
  transition: transform 0.3s ease;
  display: flex;
  align-items: center;
  height: 100%;
}

.expand-indicator.expanded {
  transform: rotate(180deg);
}

.expand-indicator i {
  font-size: 1rem;
  color: var(--grey);
}

.summary-metrics {
  display: flex;
  gap: clamp(0.5rem, 2vw, 0rem);
  color: var(--grey);
  flex-wrap: wrap;
  font-size: clamp(1.2rem, 1.2vw, 1rem);
  align-items: center;
  flex: 1;
}

.summary-metrics > span {
  white-space: nowrap;
  flex: 1 1 auto;
  min-width: max-content;
}

.metric-target {
  font-size: clamp(0.7rem, 1vw, 0.8rem);
  white-space: nowrap;
}

@media (max-width: 1200px) {
  .sales-forecast {
    padding: 1.5rem;
  }
  
  .summary-metrics {
    gap: 1rem;
  }
}

@media (max-width: 900px) {
  .sales-forecast {
    padding: 1rem;
  }
  
  .summary-metrics {
    gap: 0.75rem;
  }
}

@media (max-width: 600px) {
  .sales-forecast {
    padding: 0.5rem;
  }
  
  .business-header {
    flex-direction: column;
  }
  
  .summary-metrics {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }
  
  .summary-metrics > span {
    width: 100%;
  }
}

.business-details {
  padding-top: 1rem;
  border-top: 1px solid var(--light-grey);
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
}

.forecast-performance-link {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.forecast-performance-link :deep(.text-arrow-link) {
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
}

.forecast-performance-link :deep(.text) {
  font-family: 'Darker Grotesque', sans-serif;
  font-size: 1rem;
  font-weight: 500;
  color: var(--primary-text-color);
  transition: transform 0.2s ease;
}

.forecast-performance-link :deep(.arrow-circle) {
  width: 3rem;
  height: 3rem;
  background-color: var(--button-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.forecast-performance-link :deep(.arrow-icon) {
  width: 20px;
  height: 20px;
  transition: filter 0.3s ease;
}

.forecast-performance-link :deep(.text-arrow-link:hover .arrow-circle) {
  background-color: var(--primary-text-color);
  transform: translateX(2px);
}

.forecast-performance-link :deep(.text-arrow-link:hover .arrow-icon) {
  filter: brightness(0) invert(1);
}

.forecast-performance-link :deep(.text-arrow-link:hover .text) {
  transform: translateX(4px);
}

.forecast-table h4, .sales-chart h4 {
  font-family: 'Darker Grotesque', sans-serif;
  font-size: 1.3rem;
  font-weight: 400;
  letter-spacing: 0.05em;
  margin: 0 0 1rem 0;
  color: var(--primary-text-color);
  text-transform: uppercase;
}

.sales-chart {
  background: var(--background-color);
  opacity: 0.8;
  border-radius: 8px;
  margin-top: 2rem;
  padding: 1.5rem;
}

.chart {
  height: 300px;
  width: 100%;
}

.info-container {
  position: relative;
  display: inline-block;
}

.info-icon {
  width: 20px;
  height: 20px;
  cursor: help;
  margin-top: 0.5rem;
}

.info-tooltip {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  padding: 0.5rem 1rem;
  background-color: white;
  color: var(--primary-text-color);
  text-align: center;
  border-radius: 6px;
  font-size: 0.9rem;
  white-space: nowrap;
  box-shadow: 0 2px 4px var(--shadow-color);
  opacity: 0;
  transition: opacity 0.2s, visibility 0.2s;
}

/* Arrow for tooltip */
.info-tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

.info-container:hover .info-tooltip {
  visibility: visible;
  opacity: 1;
}

</style>
