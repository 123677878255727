import { createApp } from 'vue';
import { auth } from './utils/firebaseConfig.js';
import App from './App.vue';
import router from './router/index.js' 
import store from './utils/store.js';
import './assets/styles.css'
import VCalendar from 'v-calendar';
import 'v-calendar/style.css';

let app;

auth.onAuthStateChanged(() => {
  if (!app) {
    app = createApp(App)
      .use(router)
      .use(store)
      .use(VCalendar, {})
      .mount('#app');
  }
  
  store.dispatch('checkUser');
});
