<template>
  <div class="navbar">
    <div class="nav-top">
      <img class="logo" src="../assets/images/favicon_dark.svg" @click="$router.push('/home')">
      <div class="expand-indicator">
        <i class="fas fa-chevron-right"></i>
      </div>
      <nav class="navigation">
        <!-- Primary Navigation Links -->
        <router-link class="nav-link" to="/home">
          <div class="header-content">
            <img src="@/assets/images/icons/home_light.svg" alt="Home" class="section-icon">
            <span>Home</span>
          </div>
        </router-link>
        <router-link class="nav-link" to="/forecast">
          <div class="header-content">
            <img src="@/assets/images/icons/forecast_light.svg" alt="Forecast" class="section-icon">
            <span>Forecast</span>
          </div>
        </router-link>
        <router-link class="nav-link" to="/rapporter">
          <div class="header-content">
            <img src="@/assets/images/icons/rapporter_light.svg" alt="Rapporter" class="section-icon">
            <span>Rapporter</span>
          </div>
        </router-link>

        <!-- Businesses Section -->
        <div class="section">
          <div class="section-header" @click="toggleBusinesses" 
               :class="{ 'router-link-active': isBusinessSectionActive && !$route.path.includes('/home') && !$route.path.includes('/forecast') && !$route.path.includes('/rapporter') }">
            <div class="header-content">
              <img src="@/assets/images/icons/res_light.svg" alt="Restaurants" class="section-icon">
              <span>Restauranter</span>
            </div>
            <img src="@/assets/images/icons/chevron_light.svg" class="toggle-icon" :class="{ 'rotated': businessesExpanded }" alt="Toggle">
          </div>
          <div class="section-content" :class="{ 'expanded': businessesExpanded }">
            <router-link 
              v-for="business in userBusinesses" 
              :key="business.id"
              class="nav-link section-link" 
              :to="`/business/${encodeURIComponent(business.businessData?.businessName)}`"
            >
              {{ business.businessData?.businessName }}
            </router-link>
          </div>
        </div>

        <!-- Settings Section -->
        <div class="section">
          <div class="section-header" @click="toggleSettings"
               :class="{ 'router-link-active': isSettingsSectionActive && !$route.path.includes('/home') && !$route.path.includes('/forecast') && !$route.path.includes('/rapporter') }">
            <div class="header-content">
              <img src="@/assets/images/icons/settings_light.svg" alt="Settings" class="section-icon">
              <span>Indstillinger</span>
            </div>
            <img src="@/assets/images/icons/chevron_light.svg" class="toggle-icon" :class="{ 'rotated': settingsExpanded }" alt="Toggle">
          </div>
          <div class="section-content" :class="{ 'expanded': settingsExpanded }">
            <router-link class="nav-link section-link" to="/user">Bruger</router-link>
            <router-link class="nav-link section-link" to="/settings">Systemindstillinger</router-link>
            <router-link class="nav-link section-link" to="/admin" v-if="hasAdminAccess">Administration</router-link>
          </div>
        </div>
      </nav>
    </div>
    
    <div class="nav-bottom">
      <div class="nav-link" @click="logout">
        <div class="header-content">
          <img src="@/assets/images/icons/logout_light.svg" alt="Logout" class="section-icon">
          <span>Log ud</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { auth } from '@/utils/firebaseConfig.js';
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
  name: "UserNavbar",
  setup() {
    const store = useStore();
    
    const hasAdminAccess = computed(() => {
      const role = store.state.role;
      return role === 'admin' || role === 'owner';
    });

    return {
      hasAdminAccess
    };
  },
  data() {
    return {
      settingsExpanded: false,
      businessesExpanded: false,
    }
  },
  computed: {
    userBusinesses() {
      return this.$store.state.organizations.flatMap(org => 
        org.businesses.map(business => ({
          id: business.id || business.name,
          businessData: business.data
        }))
      );
    },
    
    isBusinessSectionActive() {
      return this.$route.path.includes('/business/');
    },
    
    isSettingsSectionActive() {
      return ['/user', '/settings', '/admin'].some(path => 
        this.$route.path.startsWith(path)
      );
    }
  },
  methods: {
    logout() {
      auth.signOut().then(() => {
        this.$store.commit('setUser', null); 
        this.$router.push({ name: 'HomepageFirstPage' });
      });
    },
    toggleSettings() {
      this.settingsExpanded = !this.settingsExpanded;
    },
    toggleBusinesses() {
      this.businessesExpanded = !this.businessesExpanded;
    },
    handleNavbarHover(isHovered) {
      if (!isHovered) {
        this.settingsExpanded = false;
        this.businessesExpanded = false;
      }
    }
  }
};
</script>

<style scoped>
.navbar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 12rem;
  padding: 1rem;
  background-color: var(--primary-text-color);
  transition: transform 0.3s ease;
  transform: translateX(-9rem);
}

.navbar:hover {
  transform: translateX(0);
}

.nav-top, .nav-bottom {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.logo {
  width: 3rem;
  height: 3rem;
  margin-bottom: 2rem;
  cursor: pointer;
}

.navigation {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.nav-link, .section-header {
  text-decoration: none;
  color: var(--navbar-text-color);
  padding: 1.5rem 1rem;
  border-radius: 0;
  transition: all 0.2s ease;
  width: 100%;
  margin-left: -1rem;
  margin-right: -1rem;
}

.nav-link:hover, .section-header:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--button-color);
}

.section {
  margin: 0;
  padding: 0;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 1rem;
  color: var(--navbar-text-color);
  cursor: pointer;
  border-radius: 0;
  transition: all 0.2s ease;
  width: 100%;
}

.header-content {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.section-icon {
  font-size: 1rem;
  width: 1.2rem;
  text-align: center;
}

.toggle-icon {
  font-size: 0.8rem;
  margin-right: 0.5rem;
}

.toggle-icon.rotated {
  transform: rotate(90deg);
}

.section-content {
  display: flex;
  flex-direction: column;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  gap: 0.25rem;
}

.section-content.expanded {
  max-height: 500px;
}

.section-link {
  display: block;
  padding: 0.75rem 1rem;
  margin: 0 -1rem;
  font-size: 0.9rem;
  opacity: 0.9;
  width: 100%;
}

.signout-button {
  width: 100%;
  color: var(--button-text-color);
  background-color: var(--button-color);
  border: none;
  padding: .5rem 1rem;
  margin-bottom: 2.5rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.signout-button:hover {
  background-color: var(--button-text-color);
  color: var(--button-color);
}

.router-link-active {
  color: var(--button-color);
  font-weight: 700;
  background-color: rgba(255, 255, 255, 0.1);
  border-left: 2px solid var(--button-color);
  padding-left: calc(1rem - 2px);
}

.header-icons {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.toggle-icon {
  font-size: 0.8rem;
}

.nav-bottom {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-bottom: 2.5rem;
}

.nav-bottom .nav-link {
  color: var(--navbar-text-color);
  cursor: pointer;
}

.nav-bottom .nav-link:hover {
  color: var(--button-color);
  background-color: transparent; 
}

:deep(.green-button::before) {
  background-color: var(--button-text-color) !important;
}

:deep(.green-button:hover) {
  color: var(--button-color);
}

.navbar:not(:hover) .nav-link span,
.navbar:not(:hover) .section-header span {
  display: none;
}

.navbar:not(:hover) .header-content {
  justify-content: flex-end !important;
  margin-right: 1rem;
}

.navbar:not(:hover) .section-icon {
  margin-right: 0;
}

.navbar:not(:hover) .router-link-active {
  border-left: 2px solid var(--button-color);
  margin-left: 8rem;
  padding-left: calc(1rem - 2px);
  width: auto;
  padding-right: 1rem;
}

.navbar:not(:hover) .router-link-active .header-content {
  margin-right: 0;
  justify-content: center !important;
}

.navbar:not(:hover) .toggle-icon {
  display: none;
}

.navbar:not(:hover) .header-content {
  justify-content: flex-end !important;
  margin-right: 1rem;
}

.navbar:not(:hover) .section-icon {
  margin-right: 0;
}

.navbar:not(:hover) .section-content {
  text-align: right;
}

.navbar:not(:hover) .section-link {
  text-align: right;
  padding-right: 1rem;
}

.navbar:not(:hover) .section .section-header .header-content {
  justify-content: flex-end !important;
  margin-right: 0;
  padding-right: 1rem;
}

.section-header .header-content {
  width: 100%;
}

.navbar:not(:hover) .logo {
  margin-left: auto;
}

.expand-indicator {
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  color: var(--navbar-text-color);
  opacity: 0.7;
  transition: opacity 0.3s ease;
}

.navbar:hover .expand-indicator {
  opacity: 0;
}

.navbar:not(:hover) .section-content.expanded {
  max-height: 0;
  padding: 0;
  overflow: hidden;
}

.navbar:not(:hover) .section-content .nav-link span {
  display: none;
}

.navbar:not(:hover) .section-content .router-link-active {
  border-left: none;
  margin-left: 0;
  padding-left: 1rem;
  background-color: transparent;
}
</style>
