<template>
  <div class="dashboard">
    <header class="header-section">
      <div class="header-content">
        <div>
          <h2>Dashboard</h2>
        </div>
      </div>
      
      <div class="date-controls">
        <div class="preset-buttons">
          <button 
            v-for="preset in datePresets" 
            :key="preset.id"
            :class="['preset-button', { active: selectedPreset === preset.id }]"
            @click="selectPreset(preset.id)"
          >
            {{ preset.label }}
          </button>
        </div>

        <div v-if="selectedPreset === 'custom'" class="custom-range">
          <div class="date-input">
            <label>Fra:</label>
            <input 
              type="date" 
              v-model="customRange.from"
              :max="today"
            >
          </div>
          <div class="date-input">
            <label>Til:</label>
            <input 
              type="date" 
              v-model="customRange.to"
              :max="today"
            >
          </div>
          <button 
            class="apply-button"
            @click="applyCustomRange"
            :disabled="!isValidDateRange"
          >
            Anvend
          </button>
        </div>
      </div>
    </header>

    <div class="report-embed">
      <iframe 
        v-if="embedUrl"
        width="100%" 
        height="100%" 
        :src="embedUrl"
        frameborder="0" 
        style="border:0;" 
        allowfullscreen
        sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox">
      </iframe>
      <div v-else class="loading">Loading...</div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import '@/assets/logged-in.css'

export default {
  name: 'DashBoard',
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const embedUrl = ref('');
    const selectedPreset = ref('7days');
    const today = new Date().toISOString().split('T')[0];
    
    // Get business name from route query if available
    const businessName = computed(() => {
      // First check if business name is in route params (new URL structure)
      if (route.params.businessName) {
        return decodeURIComponent(route.params.businessName);
      }
      return route.query.business ? decodeURIComponent(route.query.business) : null;
    });

    const customRange = ref({
      from: today,
      to: today,
      applied: false
    });

    const isValidDateRange = computed(() => {
      return customRange.value.from && customRange.value.to && 
             new Date(customRange.value.from) <= new Date(customRange.value.to);
    });

    const datePresets = [
      { id: '7days', label: 'Seneste 7 dage' },
      { id: '30days', label: 'Seneste 30 dage' },
      { id: 'year', label: 'I år' },
      { id: 'custom', label: 'Vælg periode' }
    ];

    const calculateDateRange = (preset) => {
      const end = new Date();
      end.setDate(end.getDate() - 1);
      const start = new Date(end);

      switch (preset) {
        case '7days':
          start.setDate(end.getDate() - 6);
          break;
        case '30days':
          start.setDate(end.getDate() - 29);
          break;
        case 'year':
          start.setFullYear(end.getFullYear(), 0, 1);
          break;
        case 'custom':
          if (!customRange.value.applied) return null;
          return {
            from: customRange.value.from,
            to: customRange.value.to
          };
      }

      return {
        from: start.toISOString().split('T')[0],
        to: end.toISOString().split('T')[0]
      };
    };

    const updateEmbedUrl = () => {
      const dateRange = calculateDateRange(selectedPreset.value);
      if (!dateRange) return;

      const organizations = store.state.organizations;
      if (!organizations?.length) return;

      // Find the selected business based on businessName from route query
      let selectedOrg, selectedBusiness;

      if (businessName.value) {
        // Find the business with the matching name
        for (const org of organizations) {
          const business = org.businesses.find(b => 
            b.data.businessName === businessName.value
          );
          
          if (business) {
            selectedOrg = org;
            selectedBusiness = business;
            break;
          }
        }
      } else {
        // Fallback to first business if no business is selected
        selectedOrg = organizations[0];
        selectedBusiness = selectedOrg.businesses[0];
      }

      if (!selectedOrg || !selectedBusiness) return;
      
      // Create params object with correct property names for all three data sources
      const params = {
        "ds0.oid": selectedOrg.orgName,
        "ds0.rid": selectedBusiness.name,
        "ds0.dtfrom": dateRange.from,
        "ds0.dtend": dateRange.to,
        "ds3.oid": selectedOrg.orgName,
        "ds3.rid": selectedBusiness.name,
        "ds3.dtfrom": dateRange.from,
        "ds3.dtend": dateRange.to,
        "ds7.oid": selectedOrg.orgName,
        "ds7.rid": selectedBusiness.name,
        "ds7.dtfrom": dateRange.from,
        "ds7.dtend": dateRange.to
      };

      // Encode parameters
      const paramsAsString = JSON.stringify(params);
      const encodedParams = encodeURIComponent(paramsAsString);

      // Construct the full URL with the new report ID
      const reportId = "060586d3-e40c-4a17-8dbd-56ebcf691ca0";
      const pageId = "kAEeE";
      const baseUrl = `https://lookerstudio.google.com/embed/reporting/${reportId}/page/${pageId}`;
      embedUrl.value = `${baseUrl}?params=${encodedParams}`;
    };

    const applyCustomRange = () => {
      if (isValidDateRange.value) {
        customRange.value.applied = true;
        updateEmbedUrl();
      }
    };

    const selectPreset = (preset) => {
      selectedPreset.value = preset;
      if (preset === 'custom') {
        customRange.value.applied = false;
      }
    };

    const goBack = () => {
      router.go(-1);
    };

    watch([selectedPreset, businessName], () => {
      if (selectedPreset.value !== 'custom') {
        updateEmbedUrl();
      }
    });

    onMounted(() => {
      // Initial load with 7-day preset
      updateEmbedUrl();
    });

    return {
      embedUrl,
      datePresets,
      selectedPreset,
      customRange,
      today,
      selectPreset,
      applyCustomRange,
      isValidDateRange,
      businessName,
      goBack
    };
  }
};
</script>

<style scoped>
.dashboard {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  background-color: var(--background-color);
  padding: 1rem 2rem;
}

.header-section {
  width: 100%;
  margin-bottom: 1rem;
}

.header-content {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.header-content h2 {
  font-family: 'Darker Grotesque', sans-serif;
  font-size: 2rem;
  color: var(--primary-text-color);
  margin: 0;
}

.date-controls {
  display: flex;
  gap: 1rem;
  align-items: center;
  flex-wrap: wrap;
}

.preset-buttons {
  display: flex;
  gap: 0.5rem;
}

.preset-button {
  padding: 0.4rem 0.8rem;
  border: 1px solid var(--primary-text-color);
  border-radius: 4px;
  background: var(--card-background);
  color: var(--primary-text-color);
  cursor: pointer;
  position: relative;
  overflow: hidden;
  z-index: 1;
  font-size: 0.9rem;
}

.preset-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--primary-text-color);
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
  z-index: -1;
}

.preset-button:hover::before {
  transform: translateX(0);
}

.preset-button:hover {
  color: white;
}

.preset-button.active {
  background: var(--primary-text-color);
  color: var(--white);
}

.preset-button.active:hover {
  opacity: 0.9;
}

.custom-range {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.date-input {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.9rem;
}

.date-input input {
  padding: 0.4rem;
  border: 1px solid var(--light-grey);
  border-radius: 4px;
  transition: all 0.2s;
}

.date-input input:hover {
  border-color: var(--primary-text-color);
}

.date-input input:focus {
  outline: none;
  border-color: var(--primary-text-color);
  box-shadow: 0 0 0 2px rgba(var(--button-color-rgb, 0, 123, 255), 0.2);
}

.apply-button {
  padding: 0.4rem 0.8rem;
  border: 1px solid var(--primary-text-color);
  border-radius: 4px;
  background: var(--primary-text-color);
  color: white;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  z-index: 1;
  font-size: 0.9rem;
}

.apply-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  transform: translateX(-100%);
  transition: transform 0.3s ease;
  z-index: -1;
}

.apply-button:hover:not(:disabled)::before {
  transform: translateX(0);
}

.apply-button:hover:not(:disabled) {
  color: var(--button-color);
}

.apply-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.report-embed {
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.loading {
  color: var(--grey);
  font-size: 1.2rem;
}

.header-content div {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.back-link {
  color: var(--primary-text-color);
  text-decoration: none;
  transition: transform 0.2s ease;
  display: inline-block;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.back-link:hover {
  transform: translateX(-3px) scale(1.1);
}

.back-link i {
  font-size: 1.2rem;
}
</style>
