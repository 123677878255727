<template>
  <div class="logged-out text-arrow-link" @click="$emit('click')">
    <span class="text">{{ text }}</span>
    <div class="arrow-circle">
      <img src="@/assets/images/Arrow.svg" alt="Arrow" class="arrow-icon">
    </div>
  </div>
</template>

<script>
export default {
  name: 'TextWithArrowButton',
  props: {
    text: {
      type: String,
      required: true
    }
  }
}
</script>

<style>
.text-arrow-link {
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  width: fit-content;
}

.text {
  font-family: 'Darker Grotesque', sans-serif;
  font-size: 16px;
  font-weight: 500;
  transition: color 0.2s ease;
}

.arrow-circle {
  width: 3.5rem;
  height: 3.5rem;
  background-color: var(--logged-out-button-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.arrow-icon {
  width: 24px;
  height: 24px;
  transition: filter 0.3s ease;
}

.text-arrow-link:hover .arrow-circle {
  background-color: var(--primary-text-color);
  transform: translateX(2px);
}

.text-arrow-link:hover .arrow-icon {
  filter: brightness(0) invert(1);
}
</style> 