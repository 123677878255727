<template>
  <div class="container logged-out">
    <!-- Hero Section -->
    <section class="hero">
      <div class="hero-content">
        <div class="welcome-pill">VELKOMMEN TIL DATABITE</div>
        <h1>Fremtidens værktøj til smart restaurantdrift</h1>
        <p class="description">
         Databite samler din salgsdata, gæsteanmeldelser og lønomkostninger i én enkel og overskuelig platform. 
        </p>
        <div class="hero-cta">
          <TextWithArrowButton 
            text="Vil du høre mere?" 
            @click="$router.push({ path: '/kontakt', query: { subject: 'Book et møde/opkald' }})"
          />
        </div>
      </div>
    </section>

    <!-- Image Section -->
    <section class="hero-image">
      <img src="@/assets/images/ambience/hero.jpg" alt="Hero Image" class="hero-image-item">
    </section>

    <!-- Customers Section -->
    <section class="customers">
      <div class="customers-label">TILFREDSE BRUGERE AF DATABITE</div>
      <div class="customer-logos">
        <img src="@/assets/images/customers/wmph_cross.svg" alt="Cross" class="customer-logo">
        <img src="@/assets/images/customers/wmph_aaland.svg" alt="Åland" class="customer-logo">
        <img src="@/assets/images/customers/wmph_ziggy.svg" alt="Ziggy" class="customer-logo">
        <img src="@/assets/images/customers/wmph_taq.svg" alt="TAQ" class="customer-logo">      
      </div>
    </section>

    <!-- New Stand Out Block -->
    <section class="stand-out-block">
      <div class="stand-out-content">
        <h2>Præcise forecasts, samlet overblik, og smartere beslutninger.</h2>
      </div>
    </section>

    <!-- All-in-One Solution Section -->
    <section class="all-in-one">
      <h2>Alt-i-én løsning</h2>
      <p class="all-in-one-description">Vi har integrationer med enkelte platforme. Vi er ikke begrænset til disse, og tilføjer gerne nye for at få jer med i folden.</p>
      <div class="solutions-grid">
        <div class="solution-card" v-for="(solution, index) in solutions" :key="index">
          <h3>{{ solution.title }}</h3>
          <p>{{ solution.description }}</p>
          <div class="card-footer">
            <TextWithArrowButton 
              text="Læs mere" 
              @click="navigateToProduct(solution.id)"
            />
          </div>
        </div>
      </div>
    </section>

    <!-- Value Proposition -->
    <section class="value-prop">
      <h2>Hvorfor vælge Databite?</h2>
      <div class="value-grid">
        <div class="value-item">
          <img src="@/assets/images/icons/Restaurant.svg" alt="Restaurant Icon" class="value-icon">
          <h3>DESIGNET TIL RESTAURATION</h3>
          <p>Skræddersyet til de unikke behov du møder i restaurationsbranchen</p>
        </div>

        <div class="value-item">
          <img src="@/assets/images/icons/Intuitivt.svg" alt="Intuitive Icon" class="value-icon">
          <h3>INTUITIV LØSNING</h3>
          <p>Det er nemt og hurtigt at skabe overblik, så du hurtigt kan optimere din forretning</p>
        </div>

        <div class="value-item">
          <img src="@/assets/images/icons/historisk.svg" alt="Historical Data Icon" class="value-icon">
          <h3>HISTORISK OG FREMTIDIG DATA</h3>
          <p>Historisk performance, og planlægning af fremtiden med præcise forecasts</p>
        </div>
      </div>
    </section>

    <!-- Second Stand Out Block -->
    <section class="stand-out-block">
      <div class="stand-out-content">
        <h2>Vores ambition er, at hjælpe så mange som muligt med de drilske dele af driften.</h2>
      </div>
    </section>

    <!-- FAQ Section -->
    <section class="faq">
      <h2>Ofte stillede spørgsmål</h2>
      <div class="faq-list">
        <div 
          v-for="(faq, index) in faqs" 
          :key="index"
          class="faq-item"
          :class="{ 'is-open': expandedFaq === index }"
          @click="toggleFaq(index)"
        >
          <div class="faq-question">
            <span>{{ faq.question }}</span>
            <img 
              src="@/assets/images/icons/chevron.svg" 
              alt="Expand" 
              class="chevron-icon"
            >
          </div>
          <div class="faq-answer" v-if="expandedFaq === index">
            {{ faq.answer }}
          </div>
        </div>
      </div>
    </section>

    <!-- Hero Image Section -->
    <section class="hero-image">
      <img src="@/assets/images/ambience/data.jpg" alt="Hero Image" class="hero-image-item">
    </section>
    
    <!-- Testimonials Section -->
    <!-- <section class="testimonials">
      <h2>Vores kunder siger</h2>
      <div class="testimonials-grid">
        <div class="testimonial">
          <div class="quote-content">
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation."
          </div>
          <div class="quote-author">
            <div class="author-name">John Doe</div>
            <div class="author-title">Restaurant Manager, Copenhagen</div>
          </div>
        </div>

        <div class="testimonial">
          <div class="quote-content">
            "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident."
          </div>
          <div class="quote-author">
            <div class="author-name">Jane Smith</div>
            <div class="author-title">Owner, Aarhus Café</div>
          </div>
        </div>
      </div>
    </section> -->

    <!-- Footer -->
    <SiteFooter />
  </div>
</template>

<script>
import TextWithArrowButton from '@/components/TextWithArrowButton.vue'
import SiteFooter from '@/components/SiteFooter.vue'
import '@/assets/logged-out.css'

export default {
  name: "FrontPage",
  components: {
    TextWithArrowButton,
    SiteFooter
  },
  data() {
    return {
      expandedCard: null,
      solutions: [
        {
          title: 'Salgsprognose',
          description: 'Ved brug af jeres historiske data og en række nøjeudvalgte faktorer, udregner vi en prognose for jeres fremtidige omsætning.',
          id: 'forecast'
        },
        {
          title: 'Vagtplanlægning',
          description: 'Tilpas bemandingen til dine travle perioder. Se lønomkostninger i forhold til omsætning og optimer dine vagtplaner.',
          id: 'vagtplanlaegning'
        },
        {
          title: 'Kundeanmeldelser',
          description: 'Vi bruger AI til at analysere og kondensere henvendelser fra dine gæster, så I får et overskueligt overblik over hvad I bør gøre mere eller mindre.',
          id: 'kundeanmeldelser'
        },
        {
          title: 'Brugertilpassede rapporter',
          description: 'Restauranter er ofte unikke og med forskellige behov. Med vores opsætning og jeres data, kan vi lave langt mere end det der vises i her. Vi hjælper med at bygge skræddersyede rapporter der matcher jeres behov.',
          id: 'rapporter'
        }
      ],
      expandedFaq: null,
      faqs: [
        {
          question: 'Hvem kan bruge Databite?',
          answer: 'Databite er udviklet til restauranter og caféer. Det er det vi har testet vores løsninger på, men det er ikke utænkeligt at produkterne kan anvendes meningsfuldt i andre brancher - f.eks. barer, butikker og lignende.'
        },
        {
          question: 'Hvad koster det at bruge Databite?',
          answer: 'Vi har endnu ikke fastlagte priser, prisen kan afhænge både af engagement, antal restauranter. Derudover kan der være en venteperiode i forbindelse med oprettelse, såfremt der er flere nye integrationer der skal bygges.'
        },
        {
          question: 'Hvilke systemer kan integreres til Databite?',
          answer: 'Vi integrerer i dag med Deltaplan, EasyTable og Until POS. Vi udvider konstant, og det er bestemt ikke et krav at dine systemer er i vores integrationsliste.'
        },
        {
          question: 'Bliver vores data lagret sikkert og compliant?',
          answer: 'Data bliver behandlet i et sikkert cloud miljø på Europæiske servere.'
        }
      ]
    }
  },
  methods: {
    toggleCard(index) {
      if (this.expandedCard === index) {
        this.expandedCard = null;
      } else {
        this.expandedCard = index;
      }
    },
    toggleFaq(index) {
      this.expandedFaq = this.expandedFaq === index ? null : index;
    },
    navigateToProduct(sectionId) {
      this.$router.push(`/produkter`).then(() => {
        window.scrollTo({
          top: 0,
          behavior: 'auto'
        });
                setTimeout(() => {
          const element = document.getElementById(sectionId);
          if (element) {
            element.scrollIntoView({
              behavior: 'smooth',
              block: 'start'
            });
          }
        }, 100); 
      });
    }
  }
}
</script>

<style>
.container.logged-out {
  padding: 0 2.2srem;
}

.hero-content {
  max-width: 800px;
  margin: 0 auto;
}

.welcome-pill {
  display: inline-block;
  padding: 10px 30px;
  border: 1px solid;
  border-radius: 100px;
  font-size: 14px;  
  font-weight: 400;
  letter-spacing: 0.12em;
  margin-top: 2.5rem;
  color: var(--primary-text-color);
}

.description {
  max-width: 600px;
  margin: 0 auto;
  line-height: 2;
  letter-spacing: 0.03em;
  font-size: 1.2rem;
}

.hero-content h1 {
  margin-top: 0;
}

.hero-cta {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.hero-image {
  width: 100%;
  height: 55%;
  margin: 4rem 0;
}

.hero-image-item {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 24px;
}

.hero-image:first-of-type {
  background-image: url('@/assets/images/ambience/hero.jpg');
}

.hero-image:last-of-type {
  background-image: url('@/assets/images/ambience/data.jpg');
}

.stand-out-block {
  background-color: var(--logged-out-stand-out-green);
  padding: 5rem 3.75rem;
  padding-bottom: 7rem;
  width: 100%;
  margin-left: -4rem;
}

.stand-out-content {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
}

.stand-out-block h2 {
  color: var(--primary-text-color);
  font-size: 7.2rem;
  letter-spacing: 0.02em;
  line-height: 1;
  font-weight: 500;
  font-family: 'Darker Grotesque', sans-serif;
  margin: 0rem 1.5rem;
}

@media (max-width: 768px) {
  
  .container.logged-out {
    padding: 1rem 1rem;
    gap: 1rem;
  }
  
  .welcome-pill {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  
  .hero-cta {
    margin-top: 1rem;
  }
  
  .hero-image {
    width: 110vw;
    border-radius: 0;
    margin-left: calc(-50vw + 50%);
  }
  
  .hero-image-item {
    border-radius: 0;
  }
  
  .stand-out-block {
    width: 100vw;
    margin-left: calc(-50vw + 50%);
    padding: 3rem 1.5rem;
  }
  
  .stand-out-block h2 {
    font-size: 3rem;
  }
}

.customers {
  padding: 4rem 0;
  text-align: center;
}

.customers-label {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.1em;
  margin-bottom: 3rem;
}

.customer-logos {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6rem;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  margin-bottom: 4rem;
}

.customer-logo {
  height: 60px;
  width: 100px;
  opacity: 0.6;
  filter: grayscale(100%);
  transition: all 0.2s ease;
}

.customer-logo:hover {
  opacity: 0.8;
  filter: grayscale(0%);
}

@media (max-width: 768px) {
  .customer-logos {
    gap: 2rem;
  }
  
  .customer-logo {
    height: 40px;
  }
}

.all-in-one {
  padding: 8rem 0 4rem 0;
  max-width: 1200px;
  margin: 0 auto;
}

.all-in-one h2 {
  font-size: 4rem;
  margin-bottom: 1rem;
  font-weight: 500;
  font-family: 'Darker Grotesque', sans-serif;
}

.all-in-one-description {
  max-width: 600px;
  font-size: 1.25rem;
  margin-bottom: 4rem;
  line-height: 1.6;
}


.solutions-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 2.5rem;
}

.solution-card {
  background-color: var(--dark-red-element);
  color: var(--white);
  padding: 1.5rem 2.5rem;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  min-height: 300px;
}

.solution-card h3 {
  font-size: 2rem;
  margin-bottom: 1rem;
  font-weight: 700;
  font-family: 'Darker Grotesque', sans-serif;
  color: var(--white);
}

.solution-card p {
  font-size: 1.2rem;
  flex-grow: 1;
  line-height: 1.5;
  margin-bottom: 8rem;
}

.card-footer {
  margin-top: auto;
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .solutions-grid {
    grid-template-columns: 1fr;
    margin: 0 1rem;
  }
  
  .solution-card {
    min-height: auto;
  }
}

.value-prop {
  padding: 2rem 0;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 6rem;
}

.value-prop h2 {
  font-size: 4rem;
  margin-bottom: 4rem;
  font-weight: 500;
  font-family: 'Darker Grotesque', sans-serif;
}

.value-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4rem;
  margin: 0 2rem;
}

.value-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}

.value-icon {
  width: 37px;
  height: 37px;
}

.value-item h3 {
  font-size: 1.2rem;
  letter-spacing: 0.1em;
  margin-bottom: 0rem;
  margin-top: 0.3rem;
  font-weight: 500;
  font-family: 'Darker Grotesque', sans-serif;
}

.value-item p {
  margin-top: 0.3rem;
  font-size: 1rem;
  line-height: 1.6;
  max-width: 280px;
}

@media (max-width: 768px) {
  .value-grid {
    grid-template-columns: 1fr;
    gap: 3rem;
  }
  
  .value-item p {
    max-width: 100%;
  }
}

.faq {
  max-width: 1200px;
  margin: 8rem auto;
  padding: 0 2rem;
}

.faq h2 {
  font-size: 5rem;
  margin-bottom: 4rem;
  font-weight: 400;
  font-family: 'Darker Grotesque', sans-serif;
}

.faq-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.faq-item {
  cursor: pointer;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid var(--primary-text-color);
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.1rem;
  padding: 0.9rem 0;
}

.chevron-icon {
  width: 24px;
  height: 13px;
  transition: transform 0.3s ease;
}

.is-open .chevron-icon {
  transform: rotate(180deg);
}

.faq-answer {
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  line-height: 1.6;
  font-size: 1.1rem;
}

@media (max-width: 768px) {
  .faq h2 {
    font-size: 3.5rem;
  }
  
  .faq-question {
    font-size: 1rem;
  }
}

.testimonials {
  max-width: 1200px;
  margin: 8rem auto;
  padding: 0 2rem;
}

.testimonials h2 {
  font-size: 5rem;
  margin-bottom: 4rem;
  font-weight: 400;
  font-family: 'Darker Grotesque', sans-serif;
}

.testimonials-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
}

.testimonial {
  position: relative;
  padding-left: 2rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.testimonial::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 1px;
  background-color: var(--primary-text-color);
}

.quote-content {
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 3rem;
}

.quote-author {
  margin-top: auto;
  position: absolute;
  bottom: 1rem;

}

.author-name {
  font-weight: 700;
  margin-bottom: 0.3rem;
}

.author-title {
  font-size: 0.8rem;
}

@media (max-width: 768px) {
  .testimonials h2 {
    font-size: 3.5rem;
  }

  .testimonials-grid {
    grid-template-columns: 1fr;
    gap: 3rem;
  }
}
</style>
