<template>
  <div id="app" :class="{ 'logged-in': user }">
    <site-navbar v-if="!user" @login="showLogin = true" />
    <user-navbar v-else />
    <LoginForm v-if="showLogin && !user" @close="showLogin = false" />
    <div :class="{ blur: showLogin }" class="main-content">
      <router-view :key="$route.fullPath" />
    </div>
  </div>
</template>

<script>
import { computed, watch, onMounted, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import { auth } from './utils/firebaseConfig.js';
import { mapState } from 'vuex';
import { onAuthStateChanged } from 'firebase/auth';
import SiteNavbar from '@/components/SiteNavbar.vue';
import UserNavbar from '@/components/UserNavbar.vue';
import LoginForm from '@/components/LoginForm.vue';

export default {
  data() {
    return {
      showLogin: false,
    };
  },
  computed: {
    ...mapState(['user']),
  },
  components: {
    SiteNavbar,
    LoginForm,
    UserNavbar,
  },
  created() {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.$store.dispatch('checkUser');
      } else {
        this.$store.commit('setUser', null);
      }
    });
  },
  setup() {
    const store = useStore();
    const isAuthenticated = computed(() => store.state.user !== null);
    let unsubscribeListeners = null;
    
    onMounted(() => {
      // After user is authenticated and organizations are loaded
      if (store.state.user && store.state.organizations.length > 0) {
        unsubscribeListeners = store.dispatch('setupRealTimeListeners');
      }
    });
    
    onUnmounted(() => {
      // Clean up listeners
      if (unsubscribeListeners) {
        unsubscribeListeners();
      }
    });

    watch(isAuthenticated, (newValue, oldValue) => {
      console.log('isAuthenticated changed from', oldValue, 'to', newValue);
    });

    return { isAuthenticated };
  },
};
</script>

<style>
.blur {
  filter: blur(2px);
}

#app {
  font-family: 'Darker Grotesque', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--primary-text-color);
  background-color: var(--background-color);
  display: flex;
  flex-direction: column;
}

/* Styles when the user is logged in */
.logged-in {
  display: flex;
  flex-direction: row; /* Align navbar and content horizontally */
}

.main-content {
  flex-grow: 1;
  padding: 1rem;
  transition: margin-left 0.3s ease;
}

/* Specific styles for the logged-in state */
.logged-in .main-content {
  margin-left: 4.6rem; 
}

/* Navbar styles for logged-in state */
.logged-in .navbar {
  width: 12rem;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: var(--primary-text-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  transform: translateX(-9rem); /* Partially hidden */
  transition: transform 0.3s ease;
}

/* Expand the navbar on hover */
.logged-in .navbar:hover {
  transform: translateX(0); /* Fully show the navbar */
}

/* Adjust main content when navbar is fully visible */
.logged-in .navbar:hover ~ .main-content {
  margin-left: 13rem; /* Adjust margin to account for fully visible navbar */
}

@media (max-width: 768px) {
  html, body {
    overflow-x: hidden;
    width: 100%;
    margin-left: 0;
    max-width: 100vw;
  }
  
  #app {
    overflow-x: hidden;
    width: 100%;
    max-width: 100vw;
  }
}
</style>
