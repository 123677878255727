<template>
  <div class="reset-password-container">
    <div class="reset-password-card">
      <img class="logo" src="@/assets/images/FullLogo.svg" alt="Databite Logo">
      
      <div v-if="error" class="error-message">
        {{ error }}
      </div>

      <div v-if="success" class="success-message">
        {{ success }}
      </div>

      <form v-if="!success" @submit.prevent="handleResetRequest">
        <div class="form-group">
          <label for="email">Email</label>
          <input 
            type="email" 
            id="email" 
            v-model="email"
            required
            placeholder="Indtast din email"
          >
        </div>
        
        <button 
          type="submit" 
          class="submit-button"
          :disabled="isLoading"
        >
          <span v-if="isLoading">Sender link...</span>
          <span v-else>Send link</span>
        </button>
      </form>

      <div class="back-to-login">
        <a href="#" @click.prevent="$emit('back-to-login')">Tilbage til login</a>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';

export default {
  name: 'RequestPasswordReset',
  
  setup(props, { emit }) {
    const email = ref('');
    const isLoading = ref(false);
    const success = ref('');
    const error = ref('');
    const auth = getAuth();

    const handleResetRequest = async () => {
      if (!email.value) return;
      
      isLoading.value = true;
      error.value = '';
      
      try {
        await sendPasswordResetEmail(auth, email.value);
        success.value = 'Et link til nulstilling af adgangskode er blevet sendt til din email';
        setTimeout(() => {
          emit('close');
        }, 3000);
      } catch (err) {
        error.value = 'Der opstod en fejl. Kontroller at email-adressen er korrekt';
      } finally {
        isLoading.value = false;
      }
    };

    return {
      email,
      isLoading,
      success,
      error,
      handleResetRequest
    };
  }
};
</script>

<style scoped>
.reset-password-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: var(--background-color);
  padding: 1rem;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  backdrop-filter: blur(2px);
}

.reset-password-card {
  background: var(--white);
  padding: 2rem;
  border-radius: 8px;
  width: 100%;
  max-width: 400px;
  box-shadow: 0 2px 4px var(--shadow-color);
}

.logo {
  width: 120px;
  margin: 0 auto 2rem;
  display: block;
}

.form-group {
  margin-bottom: 1.5rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  color: var(--primary-text-color);
}

input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid var(--input-border);
  border-radius: 4px;
}

.error-message {
  color: var(--error-red);
  margin-bottom: 1rem;
  text-align: center;
}

.success-message {
  color: var(--success-green);
  margin-bottom: 1rem;
  text-align: center;
}

.submit-button {
  width: 100%;
  padding: 0.75rem;
  background-color: var(--button-color);
  color: var(--button-text-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.back-to-login {
  text-align: center;
  margin-top: 1rem;
}

.back-to-login a {
  color: var(--primary-text-color);
  text-decoration: none;
}

.back-to-login a:hover {
  text-decoration: underline;
}
</style>
