<template>
  <div>
    <RequestPasswordReset 
      v-if="showResetForm" 
      @close="$emit('close')"
      @back-to-login="showResetForm = false"
    />
    
    <div v-else class="modal">
      <div class="modal-content">
        <div class="content-wrapper">
          <!-- Split container for wide screens -->
          <div class="split-container">
            <!-- Left side (gradient + logo) -->
            <div class="left-side">
              <div class="gradient-background"></div>
              <img class="logo" src="@/assets/images/FullLogo.svg" alt="Databite Logo">
            </div>
            
            <!-- Right side (login form) -->
            <div class="right-side">
              <span class="close" @click="$emit('close')">&times;</span>
              <div class="login-header">
                <h1>Velkommen tilbage</h1>
                <p>Indtast dine oplysninger</p>
              </div>
              
              <form @submit.prevent="handleLogin">
                <div class="form-group">
                  <input 
                    v-model="email" 
                    type="email" 
                    placeholder="Email"
                    required
                  >
                </div>
                
                <div class="form-group">
                  <input 
                    v-model="password" 
                    type="password" 
                    placeholder="Kodeord"
                    required
                  >
                </div>
                
                <div class="forgot-password">
                  <a href="#" @click.prevent="showResetForm = true">Glemt kodeord?</a>
                </div>
                
                <button type="submit" class="login-button">Log ind</button>
                
                <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { auth } from '@/utils/firebaseConfig';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { getFunctions, httpsCallable } from 'firebase/functions';
import RequestPasswordReset from './RequestPasswordReset.vue';

export default {
  components: {
    RequestPasswordReset
  },
  props: {
    isWide: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const email = ref('');
    const password = ref('');
    const errorMessage = ref('');
    const store = useStore();
    const router = useRouter();
    const showResetForm = ref(false);
    const message = ref(null);
    const emailForm = ref({
      subject: '',
      message: ''
    });
    const showEmailModal = ref(false);

    const handleLogin = async () => {
      try {
        await signInWithEmailAndPassword(auth, email.value, password.value);
        store.dispatch('checkUser');
        router.push('/home').catch(() => {});
        context.emit('close');
        context.emit('login-success');
      } catch (error) {
        errorMessage.value = error.message;
      }
    };

    const closeEmailModal = () => {
      showEmailModal.value = false;
      emailForm.value = {
        subject: '',
        message: ''
      };
    };

    const sendEmail = async () => {
      try {
        const functions = getFunctions(undefined, 'europe-west1');
        const sendContactEmail = httpsCallable(functions, 'sendContactEmail');
        
        await sendContactEmail({
          email: store.state.user.email,
          subject: emailForm.value.subject,
          message: emailForm.value.message
        });

        closeEmailModal();
        message.value = {
          type: 'success',
          text: 'Tak for din henvendelse! Vi vender tilbage hurtigst muligt.'
        };
      } catch (error) {
        console.error('Failed to send email:', error);
        message.value = {
          type: 'error',
          text: 'Der opstod en fejl. Prøv venligst igen senere.'
        };
      }
    };

    return {
      email,
      password,
      errorMessage,
      handleLogin,
      showResetForm,
      message,
      emailForm,
      showEmailModal,
      closeEmailModal,
      sendEmail
    };
  }
};
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(2px);
  z-index: 1000;
}

.modal-content {
  width: 90%;
  max-width: 400px;
  background: var(--background-color);
}

.content-wrapper {
  padding: 0.2rem;
  border-radius: 24px;
  background: var(--background-color);
}

.split-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: var(--background-color);
  overflow: hidden;
}

.left-side {
  display: none;
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  aspect-ratio: 16/9;
}

.gradient-background {
  position: absolute;
  inset: 0;
  background: linear-gradient(50deg, #aae591 0%, var(--background-color) 100%);
  border-radius: 2px;
}

.logo {
  position: absolute;
  bottom: 2rem;
  left: 2rem;
  width: 120px;
  opacity: 0.9;
}

/* Right side styles */
.right-side {
  padding: 2rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Form styles */
.login-header {
  margin-bottom: 2rem;
}

.login-header h1 {
  font-family: 'Darker Grotesque', sans-serif;
  font-size: 2.5rem;
  font-weight: 500;
  text-align: center;
  color: var(--primary-text-color);
  margin-top: 5rem;
  margin-bottom: 0.5rem;
}

.login-header p {
  color: var(--primary-text-color);
  text-align: center;
}

.form-group {
  margin-bottom: 1.5rem;
}

input {
  width: 90%;
  border: none;
  background-color: var(--background-color);
  border-bottom: 1px solid var(--primary-text-color);
  font-size: 1rem;
  padding: 0.5rem 0rem;
  margin-bottom: 0.25rem;
  transition: border-color 0.3s ease;
}

input:focus {
  outline: none;
  border-bottom-color: var(--success-green);
}

.forgot-password {
  text-align: right;
  margin-bottom: 2rem;
}

.forgot-password a {
  color: var(--primary-text-color);
  text-decoration: none;
  font-size: 0.9rem;
}

.login-button {
  width: auto;
  min-width: 200px;
  margin: 0 auto;
  display: block;
  padding: 0.75rem 10rem;
  background-color: #aae591;
  color: var(--primary-text-color);
  border: none;
  border-radius: 100px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 4rem;
}

.login-button:hover {
  background-color: #9ed584;
}

.close {
  position: absolute;
  right: 0.7rem;
  top: -1.5rem;
  font-size: 3.5rem;
  color: var(--grey);
  cursor: pointer;
}

.error-message {
  color: red;
  text-align: center;
  margin-top: 1rem;
}

@media (min-width: 800px) {
  .modal-content {
    max-width: 900px;
    padding: 0.6rem;
    border-radius: 16px;
  }
  
  .split-container {
    flex-direction: row;
    gap: 3rem;
    align-items: stretch;
  }
  
  .left-side {
    display: block;
    width: 54%;
  }
  
  .right-side {
    width: 46%;
    padding: 2rem 0;
  }
}
</style>
