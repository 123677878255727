<template>
  <div class="contact-page">
      <ContactForm />
  </div>
</template>

<script>
import ContactForm from '@/components/ContactForm.vue';

export default {
  name: 'ContactPage',
  components: {
    ContactForm
  }
};
</script>

<style scoped>
.contact-page {
  padding: 6rem 2rem 2rem;
  min-height: auto;
  background-color: var(--background-color);
}

.header-section {
  max-width: 800px;
  margin: 0 auto 2rem;
  text-align: center;
}

.header-section h1 {
  color: var(--primary-text-color);
  font-family: 'Questrial', sans-serif;
  margin-bottom: 0.5rem;
}

.subtitle {
  color: var(--secondary-text-color);
}

.main-content {
  max-width: 800px;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .contact-page {
    padding: 4rem 0 0;
    margin: 0;
    width: 100%;
    box-sizing: border-box;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  .header-section {
    margin-bottom: 1rem;
    padding: 0 1rem;
  }
  
  .header-section h1 {
    font-size: 1.8rem;
  }
  
  .subtitle {
    font-size: 0.9rem;
  }
  
  .main-content {
    width: 100%;
    padding: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}
</style> 