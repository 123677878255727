<template>
  <header class="header">
    <img class="logo" src="../assets/images/FullLogo.svg" @click="$router.push('/')">
    
    <nav class="desktop-nav">
      <div class="nav-links">
        <router-link class="nav-link" to="/om-databite">Om os</router-link>
        <router-link class="nav-link" to="/produkter">Produkter</router-link>
        <router-link class="nav-link" to="/kontakt">Kontakt</router-link>
      </div>
    </nav>

    <div class="login-section">
      <GreenButton class="login-button" @click="showLoginForm = true">Log ind</GreenButton>
      <LoginForm v-if="showLoginForm" @close="showLoginForm = false" @login-success="showLoginForm = false" />
    </div>

    <!-- Mobile burger menu icon -->
    <div class="burger-menu" @click="toggleMenu">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 6H21" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
        <path d="M3 12H21" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
        <path d="M3 18H21" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
      </svg>
    </div>
    
    <!-- Mobile navigation overlay -->
    <div class="mobile-nav-overlay" :class="{ 'active': menuOpen }" @click="toggleMenu"></div>
    
    <!-- Mobile navigation menu -->
    <div class="mobile-nav" :class="{ 'active': menuOpen }">
      <router-link class="nav-link" to="/om-databite" @click="toggleMenu">Om os</router-link>
      <router-link class="nav-link" to="/produkter" @click="toggleMenu">Produkter</router-link>
      <router-link class="nav-link" to="/kontakt" @click="toggleMenu">Kontakt</router-link>
      <GreenButton class="login-button" @click="showLoginForm = true; toggleMenu()">Log ind</GreenButton>
      <LoginForm v-if="showLoginForm" @close="showLoginForm = false" @login-success="showLoginForm = false" />
    </div>
  </header>
</template>


<script>
import GreenButton from './GreenButton.vue';
import LoginForm from './LoginForm.vue';

export default {
  components: {
    GreenButton,
    LoginForm,
  },
  data() {
    return {
      showLoginForm: false,
      menuOpen: false
    }
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    }
  }
};
</script>

<style scoped>
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: var(--background-color);
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;
  border-bottom: 1px solid var(--primary-text-color);
}

.logo {
  height: 1.5rem;
  cursor: pointer;
}

.desktop-nav {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.nav-links {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
}

.login-button {
  position: relative;
  padding: 0.8rem 1.4rem;
  font-family: 'Darker Grotesque', sans-serif;
  font-size: 14px;
  font-weight: 700;
  background-color: var(--logged-out-button-color);
  color: var(--logged-out-button-text-color);
  overflow: hidden;
  z-index: 1;
  transition: color 0.3s ease-in-out;
}

.login-button::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--primary-text-color);
  transform: scaleY(0);
  transform-origin: 0% 100%;
  transition: transform 0.3s ease-in-out;
  z-index: -1;
}

.login-button:hover {
  color: var(--background-color);
}

.login-button:hover::before {
  transform: scaleY(1);
}

.burger-menu {
  display: none !important;
  position: relative;
  z-index: 1001;
}

@media screen and (max-width: 768px) {
  .desktop-nav, .login-section {
    display: none !important;
  }
  
  .burger-menu {
    display: block !important;
  }
  
  .header {
    padding: 15px 20px;
  }
}

/* Mobile menu overlay styles */
.mobile-nav-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: none;
}

.mobile-nav-overlay.active {
  display: block;
}

/* Mobile navigation styles */
.mobile-nav {
  position: fixed;
  top: 0;
  right: -100%;
  width: 40%;
  max-width: 300px;
  height: 100%;
  background-color: var(--background-color);
  z-index: 1000;
  padding: 80px 20px 20px;
  transition: right 0.3s ease;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.mobile-nav.active {
  right: 0;
}

.mobile-nav .nav-link {
  display: block;
  padding: 12px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.mobile-nav .login-button {
  margin-top: 20px;
}

@media screen and (min-width: 769px) {
  .navigation {
    display: flex;
    justify-content: flex-start; 
    gap: 2rem; 
  }

  .login-section {
    justify-content: flex-end;
  }
}

.navigation {
  display: flex;
  justify-content: flex-start; 
  gap: 2rem; 
}

.login-section {
  justify-content: flex-end;
}

.nav-link {
  font-family: 'Darker Grotesque';
  font-weight: 500;
  font-size: 18px;
  text-decoration: none;
  color: var(--primary-text-color);
}
</style>
