<template>
  <div class="change-password-form">
    <form @submit.prevent="handlePasswordChange">
      <div class="form-group">
        <label for="currentPassword">Nuværende adgangskode</label>
        <input 
          type="password" 
          id="currentPassword" 
          v-model="currentPassword"
          required
        >
      </div>
      
      <div class="form-group">
        <label for="newPassword">Ny adgangskode</label>
        <input 
          type="password" 
          id="newPassword" 
          v-model="newPassword"
          required
          minlength="6"
        >
      </div>
      
      <div class="form-group">
        <label for="confirmPassword">Bekræft ny adgangskode</label>
        <input 
          type="password" 
          id="confirmPassword" 
          v-model="confirmPassword"
          required
        >
      </div>

      <div v-if="error" class="error-message">
        {{ error }}
      </div>
      
      <div v-if="success" class="success-message">
        {{ success }}
      </div>

      <button 
        type="submit" 
        class="submit-button"
        :disabled="isLoading"
      >
        <span v-if="isLoading">Ændrer adgangskode...</span>
        <span v-else>Skift adgangskode</span>
      </button>
    </form>
  </div>
</template>

<script>
import { ref } from 'vue';
import { getAuth, updatePassword, reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth';

export default {
  name: 'ChangePasswordForm',
  setup() {
    const currentPassword = ref('');
    const newPassword = ref('');
    const confirmPassword = ref('');
    const error = ref('');
    const success = ref('');
    const isLoading = ref(false);

    const handlePasswordChange = async () => {
      error.value = '';
      success.value = '';
      
      if (newPassword.value !== confirmPassword.value) {
        error.value = 'De nye adgangskoder matcher ikke';
        return;
      }

      isLoading.value = true;
      const auth = getAuth();
      const user = auth.currentUser;

      try {
        // Re-authenticate user
        const credential = EmailAuthProvider.credential(
          user.email,
          currentPassword.value
        );
        await reauthenticateWithCredential(user, credential);
        
        // Update password
        await updatePassword(user, newPassword.value);
        
        success.value = 'Din adgangskode er blevet ændret';
        currentPassword.value = '';
        newPassword.value = '';
        confirmPassword.value = '';
      } catch (err) {
        if (err.code === 'auth/wrong-password') {
          error.value = 'Nuværende adgangskode er forkert';
        } else {
          error.value = 'Der opstod en fejl. Det kan din nuværende adgangskode er forkert, eller den du forsøger at skifte til, ikke lever op til kravene';
        }
      } finally {
        isLoading.value = false;
      }
    };

    return {
      currentPassword,
      newPassword,
      confirmPassword,
      error,
      success,
      isLoading,
      handlePasswordChange
    };
  }
};
</script>

<style scoped>
.change-password-form {
  max-width: 400px;
  margin: 0 auto;
}

.form-group {
  margin-bottom: 1rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  color: var(--primary-text-color);
}

input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.error-message {
  color: #cc0000;
  margin-bottom: 1rem;
}

.success-message {
  color: #005432;
  margin-bottom: 1rem;
}

.submit-button {
  background-color: var(--button-color);
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
}

.submit-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}
</style>
