<template>
  <div class="products-page">
    <!-- Hero Section -->
    <section class="hero">
      <div class="hero-image">
        <img src="@/assets/images/ambience/data.jpg" alt="Hero background" />
      </div>
      <div class="hero-content">
        <h1>Produkter</h1>
        <h2>Sådan hjælper vi med at optimere restaurantdrift</h2>
        <p class="description">
          Databite samler din salgsdata, gæsteanmeldelser og lønomkostninger i én enkel og overskuelig platform.
          Vi har skabt en suite af værktøjer specifikt designet til restaurationsbranchen.
        </p>
      </div>
    </section>

    <!-- Forecast Section -->
    <section id="forecast" class="product-section">
      <h2>Salgsprognose</h2>
      <div class="product-content">
        <div class="product-description">
          <p class="lead-text">
            Vores avancerede forecasting-system kombinerer moderne statistiske metoder med machine learning 
            for at give præcise forudsigelser af din omsætning. Systemet analyserer automatisk dine 
            historiske data og identificerer mønstre, sæsonudsving og trends for at skabe pålidelige prognoser.
          </p>
          
          <div class="features">
            <h3>Nøglefunktioner:</h3>
            <ul>
              <li>
                <strong>Intelligent lønprocent-prognose</strong>
                <p>Vi sammenholder den forventede omsætning med planlagte lønomkostninger, så du kan 
                optimere din bemanding i god tid. Dette giver dig mulighed for at justere vagtplaner 
                proaktivt og holde dine lønomkostninger under kontrol.</p>
              </li>
              <li>
                <strong>Vejrintegration</strong>
                <p>Systemet integrerer opdaterede vejrprognoser fra YR. Selvom perfekte vejrdata ikke altid forbedrer 
                præcisionen markant, giver det værdifuld kontekst til dine prognoser og hjælper med at 
                forklare afvigelser.</p>
              </li>
              <li>
                <strong>Oversigt over begivenheder</strong>
                <p>Hold styr på events, helligdage, festivaler og andre faktorer der påvirker 
                din omsætning. I prognosen kan du se hvilke kommende events der er i kalenderen.</p>
              </li>
            </ul>
          </div>

          <div class="use-cases">
            <h3>Anvendelse i praksis:</h3>
            <p>
              Vores forecast-system bruger en udvidet ARIMA tidsseriemodel, der automatisk 
              håndterer sæsonudsving, trends og uregelmæssigheder i data. Vi renser  
              data for fejl og outliers, håndterer manglende værdier, og tilpasser til abrupte 
              ændringer i omsætningsmønstre. Dette hjælper restauranter med at reducere deres 
              lønomkostninger gennem optimeret bemanding, samtidig med at serviceniveauet 
              kan være upåvirket eller forbedret. Modellen bliver konstant mere præcis gennem automatisk læring fra nye 
              data og feedback.
            </p>
          </div>
          <div class="feedback-section">
            <h4>Er der noget vi har overset?</h4>
            <p>
              Vi ønsker at forbedre vores services, og vil meget gerne høre, hvis der er andre udfordringer omkring forecasting, som vi kan hjælpe med.           
            </p>
            <div>
              <TextWithArrowButton 
                text="Fortæl os om dine behov" 
                @click="$router.push({ path: '/kontakt', query: { subject: 'Feedback om forecast-system' }})"
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Vagtplanlægning Section -->
    <section id="vagtplanlaegning" class="product-section">
      <h2>Vagtplanlægning</h2>
      <div class="product-content">
        <div class="product-description">
          <p class="lead-text">
            Vores intelligente vagtplanlægningssystem kombinerer forecast-data med personaleomkostninger 
            for at give dig det perfekte overblik over din lønprocent. Se præcist hvor og hvornår du 
            skal justere bemandingen for at nå dine økonomiske mål, mens du sikrer optimal service til 
            dine gæster.
          </p>

          <div class="features">
            <h3>Nøglefunktioner:</h3>
            <ul>
              <li>
                <strong>Intelligent lønprocent-styring</strong>
                <p>Se din forventede lønprocent dag for dag baseret på forecast og planlagte vagter. 
                Systemet markerer automatisk dage hvor din lønprocent afviger fra dine målsætninger, 
                så du kan prioritere hvor der skal justeres. Få overblik over både den daglige, 
                ugentlige og månedlige lønprocent.</p>
              </li>
              <li>
                <strong>Dynamisk bemandingsforslag</strong>
                <p>Baseret på vores forecast foreslår systemet det optimale niveau af bemanding
                Forslagene tager højde for forskellige trends, sæsonudsving
                og historiske mønstre i din omsætning.</p>
              </li>
              <li>
                <strong>Separat fra dit eksisterende system</strong>
                <p>Vores hjælp til vagtplanlægning, skal ses som en hjælp til dit eksisterende system. 
                Systemet er ikke en erstatning for dit eksisterende system, men en hjælp til at gøre 
                dit system bedre.
                Vi rådgiver om hvilke dage der med fordel kan omprioriteres, men selve håndtering skal stadig ske i jeres eksisterende system.</p>
              </li>
            </ul>
          </div>


          <div class="use-cases">
            <h3>Anvendelse i praksis:</h3>
            <p>
              Ved at kombinere præcise forecasts med intelligent vagtplanlægning hjælper vi vores kunder 
              med at reducere unødvendig overtid og spare tid på administrativt arbejde. Den løbende 
              optimering af lønprocenten understøtter restauranter i at holde deres lønomkostninger 
              under kontrol, samtidig med at de kan opretholde et højt serviceniveau ved at have den 
              rette bemanding på de rigtige tidspunkter.
            </p>
          </div>
          <div class="feedback-section">
            <h4>Er der noget vi har overset?</h4>
            <p>
              Vi ønsker at forbedre vores services, og vil meget gerne høre, hvis der er andre udfordringer omkring vagtplanlægning, som vi kan hjælpe med.           
            </p>
            <div>
              <TextWithArrowButton 
                text="Fortæl os om dine behov" 
                @click="$router.push({ path: '/kontakt', query: { subject: 'Feedback om vagtplanlægning' }})"
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Kundeanmeldelser Section -->
    <section id="kundeanmeldelser" class="product-section">
      <h2>Kundeanmeldelser</h2>
      <div class="product-content">
        <div class="product-description">
          <p class="lead-text">
            Få det fulde overblik over dine gæsters oplevelser på tværs af alle platforme. 
            Vores system samler og analyserer automatisk anmeldelser fra forskellige kilder, 
            så du slipper for at skulle hoppe fra platform til platform for at få indblik i hvad dine gæster siger.
          </p>

          <div class="features">
            <h3>Nøglefunktioner:</h3>
            <ul>
              <li>
                <strong>Samlet overblik</strong>
                <p>Vi indsamler og præsenterer automatisk alle dine anmeldelser fra forskellige platforme i ét samlet overblik. 
                Dette sparer dig for tiden med at tjekke diverse platforme og giver et helstøbt billede 
                af dine gæsters tilfredshed.</p>
              </li>
              <li>
                <strong>AI Opsummering</strong>
                <p>Vi anvender AI til at analysere og opsummere dine seneste anmeldelser til 
                letforståelige hovedpointer der kan handles på. Du får hurtigt indblik i tilbagevendende temaer, både 
                positive og negative, uden at skulle læse hver enkelt anmeldelse.</p>
              </li>
              <li>
                <strong>Net Promoter Score (NPS)</strong>
                <p>Vi beregner din NPS-score baseret på gæsternes anmeldelser. NPS er en 
                internationalt anerkendt standard for at måle kundeloyalitet og tilfredshed, der viser 
                hvor mange af dine gæster der vil anbefale din restaurant til andre. Dette giver dig et 
                klart benchmark for din performance og mulighed for at følge udviklingen over tid.</p>
              </li>
              <li>
                <strong>Kategoriseret analyse</strong>
                <p>Alle anmeldelser kategoriseres automatisk i relevante områder som mad, service, 
                atmosfære og værdi for pengene. For hver kategori kan du se både den gennemsnitlige 
                score og specifikke kommentarer, hvilket gør det nemt at identificere præcis hvor du 
                klarer dig godt, og hvor der er plads til forbedring.</p>
              </li>
            </ul>
          </div>

          <div class="use-cases">
            <h3>Anvendelse i praksis:</h3>
            <p>
              Ved at samle og analysere gæsternes feedback systematisk kan restauranter hurtigt 
              identificere og reagere på både positive og negative trends. Dette giver mulighed for at 
              fastholde det der fungerer godt og hurtigt adressere eventuelle udfordringer, før de 
              udvikler sig til større problemer. Den strukturerede tilgang til feedback-håndtering 
              hjælper med at skabe en mere datadrevet tilgang til at forbedre gæsteoplevelsen.
            </p>
          </div>
          <div class="feedback-section">
            <h4>Er der noget vi har overset?</h4>
            <p>
              Vi ønsker at forbedre vores services, og vil meget gerne høre, hvis der er andre udfordringer omkring kundefeedback, som vi kan hjælpe med.           
            </p>
            <div>
              <TextWithArrowButton 
                text="Fortæl os om dine behov" 
                @click="$router.push({ path: '/kontakt', query: { subject: 'Feedback om kundefeedback-system' }})"
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Rapporter Section -->
    <section id="rapporter" class="product-section">
      <h2>Brugertilpassede Rapporter</h2>
      <div class="product-content">
        <div class="product-description">
          <p class="lead-text">
            Vi hjælper dig med at få indsigt i præcis de områder af din forretning, som du ønsker at 
            undersøge nærmere. Vi kan udarbejde specialiserede rapporter og undersøgelser 
            baseret på dine specifikke behov og spørgsmål. Uanset om du vil undersøge effekten af 
            ændringer i din forretning eller ønsker at optimere specifikke områder, kan vi hjælpe med 
            at give dig det rette beslutningsgrundlag.
          </p>

          <div class="features">
            <h3>Eksempler på analyser:</h3>
            <ul>
              <li>
                <strong>Menuændringer og impact</strong>
                <p>Analyse af hvordan ændringer i sortiment, portionsstørrelser eller leverandører 
                påvirker salg og kundetilfredshed. For eksempel kan vi undersøge udviklingen i ølsalget 
                efter et skift i glasstørrelse eller ny leverandør, analysere hvordan en ny menu har 
                påvirket den gennemsnitlige ordrestørrelse, eller hvordan ændringer i portionsstørrelser 
                påvirker både kundetilfredshed og indtjening. Vi kan også analysere cross-selling 
                potentialet mellem forskellige menupunkter og identificere optimale prisstrategier.</p>
              </li>
              <li>
                <strong>Fraud-detektion</strong>
                <p>Specialiserede rapporter der hjælper med at identificere potentielle 
                uregelmæssigheder i transaktioner og kasseadfærd. Dette kan omfatte analyse af 
                annulleringer, rabatter, returvarer, eller usædvanlige mønstre i betalingsmetoder. 
                Vi kan også undersøge tidspunkter for kasseåbninger, varesalg uden for normal åbningstid, 
                eller identificere afvigende adfærd i forhold til normale driftsmønstre. Rapporterne kan 
                tilpasses jeres specifikke setup og kontrolprocedurer.</p>
              </li>
              <li>
                <strong>Kundeadfærd</strong>
                <p>Dybdegående analyser af kundemønstre og købsadfærd over tid. Vi kan 
                undersøge hvordan forskellige tidspunkter og ugedage påvirker jeres ordresammensætning, 
                identificere hvilke retter der ofte bestilles sammen, eller analysere effekten af 
                kampagner og menukort-ændringer
                </p>
              </li>
              <li>
                <strong>Performance-analyse</strong>
                <p>Sammenligning af nøgletal på tværs af forskellige perioder eller lokationer. Dette 
                kan omfatte analyse af hvordan forskellige afdelinger håndterer spidsbelastninger, 
                sammenligning af effektivitet på forskellige tidspunkter, eller identificering af best 
                practices fra højtydende perioder eller lokationer. Vi kan også analysere hvordan 
                forskellige teams påvirker kundetilfredshed, omsætning per gæst, eller andre KPI'er, 
                og identificere hvilke faktorer der bidrager til særligt succesfulde resultater.</p>
              </li>
            </ul>
          </div>

          <div class="use-cases">
            <h3>Sådan fungerer det:</h3>
            <p>
              Fortæl os hvad du gerne vil undersøge eller få mere indsigt i, så udarbejder vores 
              analyseteam en skræddersyet rapport baseret på dine data. Vi kan kombinere data fra 
              forskellige kilder og perioder for at give dig præcis den indsigt, du har brug for til 
              at træffe bedre beslutninger for din forretning. Vores team har erfaring med mange 
              forskellige analysetyper og kan ofte bidrage med perspektiver og vinkler, som du måske 
              ikke havde overvejet. Vi lægger vægt på at levere konkrete, handlingsorienterede 
              indsigter, der kan omsættes direkte til forbedringer i din forretning. Har du flere 
              restauranter, vil analyserne naturligvis være tilgængelige på tværs af alle dine 
              lokationer, så du kan sammenligne og implementere forbedringer i hele din virksomhed.
            </p>
          </div>
          <div class="feedback-section">
            <h4>Er der noget vi har overset?</h4>
            <p>
              Vi ønsker at forbedre vores services, og vil meget gerne høre, hvis der er andre analyser eller rapporter, som vi kan hjælpe med.           
            </p>
            <div class="feedback-cta">
              <TextWithArrowButton 
                text="Fortæl os om dine behov" 
                @click="$router.push({ path: '/kontakt', query: { subject: 'Feedback om rapporter og analyser' }})"
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- CTA Section -->
    <section class="cta-section">
      <h2>Klar til at optimere din restaurantdrift?</h2>
      <p>
        Lad os vise dig hvordan Databite kan hjælpe din forretning med at træffe 
        bedre beslutninger baseret på data.
      </p>
      <div class="hero-cta">
        <TextWithArrowButton text="Kontakt os" @click="$router.push('/kontakt', { query: { subject: 'Book møde/opkald' }})" />
      </div>
    </section>
  </div>
</template>

<script>
import TextWithArrowButton from '@/components/TextWithArrowButton.vue'
import '@/assets/logged-out.css'

export default {
  name: 'ProductPage',
  components: {
    TextWithArrowButton
  }
}
</script>

<style scoped>
.hero {
  position: relative;
  padding: 12rem 2rem;
  width: 100%;
  margin-left: -1.5rem;
  margin-top: -1.7rem;
  text-align: center;
  overflow: hidden;
}

.hero-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.hero-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero-image::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, var(--dark-red-element) 0%, rgba(255, 255, 255, 0.9) 100%);
}

.hero-content {
  position: relative;
  z-index: 2;
}

.hero h1 {
  font-size: 2.5rem;
  color: var(--white);
  margin-bottom: 2rem;
  opacity: 0.1;
}

.hero h2 {
  font-size: 1.5rem;
  color: var(--white);
  margin-bottom: 1rem;
}

.hero .description {
  max-width: 800px;
  font-size: 1.2rem;
  font-weight: 500;
  margin: 0 auto;
  color: var(--white);
}

.product-nav {
  position: sticky;
  top: 80px;
  background: var(--white);
  padding: 1rem;
  display: flex;
  justify-content: center;
  gap: 2rem;
  border-bottom: 1px solid var(--border-color);
  z-index: 100;
}

.product-nav a {
  color: var(--primary-text-color);
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.product-nav a:hover {
  color: var(--primary-color);
}

.product-section {
  padding: 4rem 2rem;
  max-width: 1200px;
  margin: 0 auto;
  scroll-margin-top: 100px;
}

.product-section:nth-child(odd) {
  background: var(--light-background);
}

.product-section h2 {
  font-size: 2.5rem;
  color: var(--primary-text-color);
  margin-bottom: 2rem;
}

.product-section p {
  font-size: 1.5rem;
  color: var(--secondary-text-color);
  margin-bottom: 2rem;
  line-height: 1.2;
}

.product-section h3 {
  font-size: 1.8rem;
  color: var(--secondary-text-color);
  margin-bottom: 2rem;
  line-height: 1.6;
}

.lead-text {
  font-size: 1.5rem;
  color: var(--secondary-text-color);
  margin-bottom: 2rem;
  line-height: 1.6;
}

.features {
  margin: 3rem 0;
}

.features ul {
  list-style: none;
  padding: 0;
}

.features li {
  margin-bottom: 2rem;
}

.features li strong {
  display: block;
  color: var(--primary-text-color);
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.use-cases {
  padding: 2rem;
  border-radius: 8px;
  margin: 3rem 0;
}

/* Different gradients for each section */
#forecast .use-cases {
  background: linear-gradient(5deg, var(--logged-out-stand-out-green) 0%, var(--white) 100%);
}

#vagtplanlaegning .use-cases {
  background: linear-gradient(270deg, var(--logged-out-stand-out-green) 0%, var(--white) 100%);
}

#kundeanmeldelser .use-cases {
  background: linear-gradient(178deg, var(--logged-out-stand-out-green) 0%, var(--white) 100%);   
}   

#rapporter .use-cases {
  background: linear-gradient(350deg, var(--logged-out-stand-out-green) 0%, var(--white) 100%);
}

.cta-section {
  text-align: center;
  padding: 4rem 2rem;
  background: var(--dark-red-element);
  color: var(--white);
}

.cta-section h2 {
  margin-bottom: 1rem;
}

.cta-section p {
  margin-bottom: 2rem;
}

.feedback-section {
  margin: 4rem 0;
}

.feedback-cta {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}


@media (max-width: 768px) {

  .product-nav {
    flex-wrap: wrap;
    gap: 1rem;
  }
  
  .product-section {
    padding: 2rem 1rem;
  }
  .hero {
    padding: 6rem 1rem 4rem 1.5rem;
  }
}

html {
  scroll-behavior: smooth;
}
</style> 