<template>
  <div class="container">
    <div class="page-content">
      <!-- Header Section -->
      <header class="header-section">
        <h1>Velkommen tilbage {{ userName }}</h1>
      </header>

      <!-- Quick Navigation Blocks -->
      <div class="section-title-pill">GENVEJE</div>
      <section class="quick-nav">
        <router-link to="/forecast" class="nav-block">
          <img src="@/assets/images/icons/forecast.svg" alt="Forecast icon" />
          <h3>Forecast & lønudgifter</h3>
          <p>Se forecast og planlæg vagter</p>
        </router-link>

        <router-link to="/rapporter" class="nav-block">
          <img src="@/assets/images/icons/rapporter.svg" alt="Rapporter icon" />
          <h3>Rapporter</h3>
          <p>Detaljerede rapporter</p>
        </router-link>

        <router-link to="/business" class="nav-block">
          <img src="@/assets/images/icons/res.svg" alt="Restaurant icon" />
          <h3>Restauranter</h3>
          <p>Se dine restauranter</p>
        </router-link>

        <div class="nav-block disabled">
          <img src="@/assets/images/icons/kundeanmeldelser.svg" alt="Gæstehenvendelser icon" />
          <h3>Gæstehenvendelser</h3>
          <p>Få indblik i dine gæsters oplevelser</p>
          <span class="coming-soon">Kommer snart</span>
        </div>
      </section>

      <!-- Quick Overview Section -->
      <section class="quick-overview">
        <div class="section-title-pill">DAGENS SALG</div>
        <div class="quick-overview-cards">
          <div 
            v-for="business in userBusinesses" 
            :key="business.id"
            class="overview-card"
          >
            <h3>{{ business.businessData?.businessName }}</h3>
            <div class="sales-bars">
              <div class="sales-bar-container">
                <!-- Progress bar background with forecast marker -->
                <div class="forecast-marker" 
                  :class="{ 'exceeded': getTotalDailySales(business) > getDailyTarget(business) }"
                  :style="{ 
                    left: `${(getDailyTarget(business) / getMaxDisplayValue(business)) * 100}%`, 
                    color: business.businessData?.colorScheme?.primary || '#005432' 
                  }"
                >
                  {{ formatCurrency(getDailyTarget(business)) }}
                </div>
                
                <!-- Main sales bar -->
                <div 
                  class="sales-progress main"
                  :style="{
                    width: `${((business.businessData?.dailySales?.paid?.amount || 0) / getMaxDisplayValue(business)) * 100}%`,
                    backgroundColor: business.businessData?.colorScheme?.primary || '#005432'
                  }"
                ></div>
                <!-- Unpaid portion -->
                <div 
                  class="sales-progress unpaid"
                  :style="{
                    left: `${((business.businessData?.dailySales?.paid?.amount || 0) / getMaxDisplayValue(business)) * 100}%`,
                    width: `${((business.businessData?.dailySales?.notPaid?.amount || 0) / getMaxDisplayValue(business)) * 100}%`,
                    backgroundColor: 'var(--error-red)'
                  }"
                ></div>
              </div>
            </div>
            <div class="sales-legend">
              <div class="amount-container">
                <div class="sales-comparison">
                  <p class="overview-value">
                    {{ formatCurrency(getTotalDailySales(business)) }}
                    <span class="comparison-indicator" 
                      :style="{ 
                        color: business.businessData?.colorScheme?.primary || '#005432',
                        backgroundColor: 'var(--light-grey)'
                      }"
                    >
                      {{ Math.round((getTotalDailySales(business) / getDailyTarget(business) * 100)) }}%
                    </span>
                  </p>
                </div>
                <p class="unpaid-note">
                  ({{ formatCurrency(business.businessData?.dailySales?.notPaid?.amount || 0) }} ubetalt)
                </p>
              </div>
              <p class="overview-subtitle">Dagens forecast: {{ formatCurrency(getDailyTarget(business)) }}</p>
              <div class="bottom-row">
                <p class="latest-order" v-if="formatLatestOrderDate(business)">
                  {{ formatLatestOrderDate(business) }}
                </p>
                <router-link 
                  :to="`/business/${encodeURIComponent(business.businessData?.businessName)}/sales`" 
                  class="business-details-button"
                >
                  <TextWithArrowButton />
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </section>


        <section v-if="userBusinesses.length > 0">
          <div class="section-title-pill">OVERBLIK</div>

          <div class="business-grid">
            <!-- Overview Dashboard for each business -->          
            <section
              v-for="business in userBusinesses"
              :key="business.id"
              class="overview-dashboard"
              :class="{ 'expanded': expandedBusinesses[business.id] }"
              @click="toggleBusinessExpand(business.id)"
            >
              <div class="dashboard-header">
                <h2>{{ business.businessData?.businessName }}</h2>
                <div class="vertical-divider"></div>
                <div class="basic-metrics">
                  <span class="metric-label">Index</span>
                  <span class="metric-value" :style="{ color: business.businessData?.colorScheme?.primary || '#005432' }">
                    {{ business.businessData?.prevWeekSales?.index || 0 }}
                  </span>
                </div>
                <span class="expand-indicator" :class="{ 'expanded': expandedBusinesses[business.id] }">
                  <i class="fas fa-chevron-down"></i>
                </span>
              </div>

              <!-- Expandable content -->
              <div class="expanded-content" v-if="expandedBusinesses[business.id]">
                <div class="horizontal-divider"></div>
                
                <div class="metrics-grid">
                  <!-- Weekly Metrics -->
                  <div class="metrics-section">
                    <h4>SIDSTE 7 DAGES NØGLETAL</h4>
                    <div class="metrics-content">
                      <div class="metric-group">
                        <span class="metric-label">Omsætning i kr</span>
                        <span class="metric-value" :style="{ color: business.businessData?.colorScheme?.primary || '#005432' }">
                          {{ formatCurrency(getWeeklyTotal(business.businessData?.prevWeekSales)) }}
                        </span>
                      </div>
                      <div class="metric-group">
                        <div class="salary-metric">
                          <span class="metric-label">Lønprocent</span>
                          <div class="salary-row">
                            <span 
                              class="salary-box" 
                              :style="{ 
                                backgroundColor: calculateSalaryPercentage(business) > (business.businessData?.salaryTarget?.[getCurrentMonth()] || 0) 
                                  ? 'rgba(255, 0, 0, 0.1)' 
                                  : 'rgba(0, 128, 0, 0.1)',
                                color: business.businessData?.colorScheme?.primary || '#005432'
                              }"
                            >
                              {{ calculateSalaryPercentage(business) }}%
                            </span>
                            <span class="metric-target">KPI: {{ business.businessData?.salaryTarget?.[getCurrentMonth()] || 0 }}%</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Customer Reviews -->
                  <div class="metrics-section">
                    <h4>KUNDEANMELDELSER</h4>
                    <div class="reviews-content">
                      <div class="score-container">
                        <span class="review-score">{{ business.businessData?.reviews?.averageScore || 0 }}</span>
                        <div class="star-rating">
                          <template v-for="index in 5" :key="index">
                            <img 
                              :src="getStarIcon((business.businessData?.reviews?.averageScore || 0) - (index - 1))"
                              :alt="getStarType((business.businessData?.reviews?.averageScore || 0) - (index - 1))"
                              class="star-icon"
                            />
                          </template>
                        </div>
                      </div>
                      <div class="vertical-divider"></div>
                      <div class="review-summary">
                        <p>{{ business.businessData?.reviews?.commentSummary || 'Ingen anmeldelser' }}</p>
                        <span class="ai-note">AI-genereret opsummering af seneste anmeldelser</span>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Sales Chart -->
                <div class="sales-chart">
                  <h4>Sidste 7 dages salg</h4>
                  <v-chart 
                    class="chart" 
                    :option="getChartOption(
                      business.businessData.prevWeekSales, 
                      business.businessData.prevWeekExpenses,
                      business.businessData.colorScheme?.primary
                    )" 
                    autoresize
                  />
                </div>
              </div>
            </section>
          </div>
        </section>

        <!-- Message if user has no businesses -->
        <section v-else>
          <p>Du har ingen tilknyttede restauranter.</p>
        </section>
    </div>
  </div>
</template>

<script>
import { computed, ref, onMounted, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import VChart from 'vue-echarts';
import { use } from 'echarts/core';
import { BarChart, GaugeChart } from 'echarts/charts';
import {
  TitleComponent,
  TooltipComponent,
  GridComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import '@/assets/base-styles.css'  
import '@/assets/logged-in.css'    
import TextWithArrowButton from '@/components/TextWithArrowButton.vue'
import { db, onSnapshot, doc } from '@/utils/firebaseConfig';

// Register ECharts components
use([
  CanvasRenderer,
  BarChart,
  GaugeChart,
  TitleComponent,
  TooltipComponent,
  GridComponent
]);

export default {
  name: 'LoggedInHomePage',
  components: {
    VChart,
    TextWithArrowButton,
  },
  setup() {
    const store = useStore();
    const expandedBusinesses = ref({});
    const unsubscribers = ref([]);

    const userName = computed(() => store.state.user?.displayName || '');
    const organizations = computed(() => store.state.organizations || []);

    const userBusinesses = computed(() => {
      const businesses = [];
      organizations.value.forEach(org => {
        org.businesses.forEach(business => {
          businesses.push({
            orgName: org.orgName,
            id: business.name,
            businessData: business.data,
          });
        });
      });
      return businesses;
    });

    const toggleBusinessExpand = (businessId) => {
      expandedBusinesses.value[businessId] = !expandedBusinesses.value[businessId];
    };

    const formatDate = (dateString) => {
      return new Date(dateString).toLocaleDateString('da-DK', { weekday: 'short' });
    };

    const formatCurrency = (amount) => {
      return new Intl.NumberFormat('da-DK', {
        style: 'currency',
        currency: 'DKK',
        maximumFractionDigits: 0
      }).format(amount);
    };

    const getSortedSales = (salesData) => {
      return Object.entries(salesData)
        .filter(([key]) => key !== 'index')
        .map(([date, sales]) => ({ date, sales }))
        .sort((a, b) => new Date(a.date) - new Date(b.date));
    };

    const getChartOption = (salesData, expensesData, primaryColor) => {
      const sortedData = getSortedSales(salesData);
      const sortedExpenses = expensesData ? 
        sortedData.map(item => expensesData[item.date] || 0) : 
        [];

      // Calculate salary percentage for each day
      const salaryPercentages = sortedData.map((item, index) => {
        const sales = item.sales;
        const expenses = sortedExpenses[index];
        return sales ? Math.round((expenses / sales) * 100) : 0;
      });

      return {
        grid: {
          left: '8%',
          right: '4%',
          bottom: '8%',
          top: '15%',
          containLabel: false
        },
        tooltip: {
          trigger: 'axis',
          formatter: (params) => {
            const sales = params[0].value;
            const expenses = params[1].value;
            const tooltipDate = new Date(sortedData[params[0].dataIndex].date);
            const formattedDate = `Dato: ${tooltipDate.toLocaleDateString('da-DK', {
              weekday: 'long'
            })} ${tooltipDate.toLocaleDateString('da-DK', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric'
            }).replace(/\./g, '-')}`;
            const salaryPercentage = salaryPercentages[params[0].dataIndex];
            
            return `${formattedDate}<br/>` +
                   `Omsætning: ${formatCurrency(sales)}<br/>` +
                   `Udgifter: ${formatCurrency(expenses)}<br/>` +
                   `Lønprocent: ${salaryPercentage}%`;
          }
        },
        legend: {
          data: ['Omsætning', 'Udgifter'],
          right: '4%',
          top: '0%'
        },
        xAxis: {
          type: 'category',
          data: sortedData.map(item => ({
            value: formatDate(item.date),
            textStyle: {
              lineHeight: 20
            }
          })),
          splitLine: {
            show: false
          },
          axisLabel: {
            formatter: (value, index) => {
              const date = new Date(sortedData[index].date);
              return [
                formatDate(date), // Weekday
                date.toLocaleDateString('da-DK', { // Date
                  day: 'numeric',
                  month: 'numeric'
                })
              ].join('\n');
            },
            align: 'center',
            interval: 0,
            lineHeight: 20
          }
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: 'var(--grey)',
              width: 0.1
            }
          },
          axisLabel: {
            formatter: (value) => formatCurrency(value),
            align: 'right',
            margin: 20
          }
        },
        series: [
          {
            name: 'Omsætning',
            data: sortedData.map(item => item.sales),
            type: 'bar',
            itemStyle: { 
              color: primaryColor || '#005432',
              opacity: 0.8
            },
            barWidth: '40%',
            barGap: '10%',
            label: {
              show: true,
              position: 'top',
              formatter: (params) => formatCurrency(params.value),
              color: '#003224'
            }
          },
          {
            name: 'Udgifter',
            data: sortedExpenses,
            type: 'bar',
            itemStyle: { 
              color: '#FF9999',
              opacity: 0.8
            },
            barWidth: '40%',
            barGap: '10%',
            label: {
              show: true,
              position: 'top',
              formatter: (params) => formatCurrency(params.value),
              color: '#003224'
            }
          }
        ]
      };
    };

    const getTotalDailySales = (business) => {
      const dailySales = business.businessData?.dailySales;
      if (!dailySales) return 0;
      return (dailySales.paid?.amount || 0) + (dailySales.notPaid?.amount || 0);
    };

    const getDailyTarget = (business) => {
      // Get the date from the latest order
      const latestOrderDate = (() => {
        const paid = business.businessData?.dailySales?.paid?.latestOrderTime;
        const notPaid = business.businessData?.dailySales?.notPaid?.latestOrderTime;
        
        // Get the most recent date
        const dates = [paid, notPaid].filter(Boolean);
        if (!dates.length) return null;
        
        const latestDate = new Date(Math.max(...dates.map(date => new Date(date))));
        return latestDate.toISOString().split('T')[0]; // Convert to YYYY-MM-DD format
      })();

      // If no order date is found, return 0
      if (!latestOrderDate) return 0;

      // Check forecast for the order date
      const forecastData = business.businessData?.comingWeek?.[latestOrderDate];
      
      if (forecastData?.arimaTs) {
        return Math.round(forecastData.arimaTs);
      }
      
      // Fallback to previous week's average if no forecast is available
      const prevWeekSales = business.businessData?.prevWeekSales;
      if (!prevWeekSales) return 0;
      const values = Object.values(prevWeekSales);
      const average = values.reduce((acc, val) => acc + val, 0) / values.length;
      return Math.round(average);
    };

    const getProgressPercentage = (business) => {
      const total = getTotalDailySales(business);
      const target = getDailyTarget(business);
      return target ? Math.round((total / target) * 100) : 0;
    };

    const getWeeklyTotal = (salesData) => {
      if (!salesData) return 0;
      return Object.values(salesData).reduce((sum, value) => sum + value, 0);
    };

    const calculateSalaryPercentage = (business) => {
      const totalSales = getWeeklyTotal(business.businessData?.prevWeekSales);
      const totalExpenses = getWeeklyTotal(business.businessData?.prevWeekExpenses);
      if (!totalSales) return 0;
      return Math.round((totalExpenses / totalSales) * 100);
    };

    const getCurrentMonth = () => {
      const month = new Date().getMonth() + 1;
      return month.toString().padStart(2, '0'); 
    };

    const getCurrentMonthName = () => {
      return new Date().toLocaleString('da-DK', { month: 'long' });
    };

    const formatLatestOrderDate = (business) => {
      const paid = business.businessData?.dailySales?.paid?.latestOrderTime;
      const notPaid = business.businessData?.dailySales?.notPaid?.latestOrderTime;
      
      // Get the most recent date
      const dates = [paid, notPaid].filter(Boolean);
      if (!dates.length) return null;
      
      const latestDate = new Date(Math.max(...dates.map(date => new Date(date))));
      return latestDate.toLocaleDateString('da-DK');
    };

    const getStarIcon = (value) => {
      if (value >= 0.75) {
        return require('@/assets/images/icons/star full.svg');
      } else if (value >= 0.25) {
        return require('@/assets/images/icons/star half.svg');
      } else {
        return require('@/assets/images/icons/star.svg');
      }
    };

    const getStarType = (value) => {
      if (value >= 0.75) {
        return 'Full star';
      } else if (value >= 0.25) {
        return 'Half star';
      } else {
        return 'Empty star';
      }
    };

    const getMaxDisplayValue = (business) => {
      const totalSales = getTotalDailySales(business);
      const target = getDailyTarget(business);
      
      return totalSales > target ? totalSales : target * 1.1;
    };

    // Set up real-time listeners for each business
    onMounted(() => {
      organizations.value.forEach(org => {
        org.businesses.forEach(business => {
          // Set up a listener for each business
          const orgRef = doc(db, 'organizations', org.orgName);
          const unsubscribe = onSnapshot(orgRef, (snapshot) => {
            if (snapshot.exists()) {
              const orgData = snapshot.data();
              const updatedBusinessData = orgData.businesses[business.name];
              
              // Update the business data in the store
              store.commit('UPDATE_BUSINESS_DATA', {
                orgName: org.orgName,
                businessId: business.name,
                data: updatedBusinessData
              });
            }
          });
          
          unsubscribers.value.push(unsubscribe);
        });
      });
    });

    // Clean up listeners when component is unmounted
    onUnmounted(() => {
      unsubscribers.value.forEach(unsubscribe => unsubscribe());
    });

    return {
      userName,
      userBusinesses,
      expandedBusinesses,
      toggleBusinessExpand,
      formatDate,
      formatCurrency,
      getSortedSales,
      getChartOption,
      getTotalDailySales,
      getDailyTarget,
      getProgressPercentage,
      getWeeklyTotal,
      calculateSalaryPercentage,
      getCurrentMonth,
      getCurrentMonthName,
      formatLatestOrderDate,
      getStarIcon,
      getStarType,
      getMaxDisplayValue,
    };
  },
};
</script>

<style scoped>
.container {
  padding: 0;
  background-color: var(--background-color);
  font-family: 'Darker Grotesque', sans-serif;
  display: flex;
  justify-content: center;
}

.page-content {
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-section {
  width: 100%;
  text-align: center;
}

.header-section h1 {
  font-family: 'Darker Grotesque', sans-serif;
  font-weight: 500;
  font-size: 4rem;
}

.header-section h2 {
  font-family: 'Darker Grotesque', sans-serif;
  font-size: 1.5rem;
}

.main-content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
}

section {
  margin-bottom: 1.5rem;
  border-radius: 8px;
  width: 100%;
}

section h2 {
  font-family: 'Darker Grotesque', sans-serif;
  font-size: 2rem;
  color: var(--primary-text-color);
}

section h3 {
  font-family: 'Darker Grotesque', sans-serif;
  font-size: 1.25rem;
  color: var(--button-color);
}

section p {
  color: var(--primary-text-color);
}

.multimedia-elements {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.button-container {
  text-align: right;
  margin-top: 1rem;
}

.sales-chart {
  margin: 0;
  padding: 1rem;
  background: white;
  border-radius: 8px;
}

.sales-chart h4 {
  font-family: 'Darker Grotesque', sans-serif;
  font-size: 1rem;
  font-weight: 500;
  margin: 0 0 1.5rem 1rem;
  color: var(--primary-text-color);
  text-align: left;
}

.chart {
  height: 300px;
  width: 100%;
}

.quick-overview {
  width: 100%;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
}

.quick-overview h2 {
  font-family: 'Darker Grotesque', sans-serif;
  color: var(--primary-text-color);
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.quick-overview-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
  align-items: start;
  width: 100%;
}

.overview-card {
  text-align: left;
  background: white;
  padding: 1rem 1.75rem 1rem 1rem;
  border-radius: 8px;
  min-width: 0;
}

.overview-card h3 {
  color: var(--primary-text-color);
  font-size: 2rem;
  margin-bottom: 0rem;
  margin-top: 0rem;
  font-family: 'Darker Grotesque', sans-serif;
}

.overview-value {
  font-size: 1.7rem;
  font-weight: 500;
  color: var(--primary-text-color);
  margin: 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.overview-subtitle {
  font-size: 1rem;
  color: var(--grey);
  margin: 0.25rem 0 0 0;
  text-align: left;
}

.overview-dashboard {
  background: white;
  padding: 1rem;
  box-shadow: none;
  max-width: 98.7%;
  margin-left: auto;
  margin-right: auto;
}

.overview-dashboard:last-child {
  margin-bottom: 0;
}

.overview-dashboard h2 {
  margin-bottom: 0.5rem;
}

.overview-dashboard h3 {
  margin-bottom: 1rem;
}
.score {
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 0.5rem;
}

.ai-summary {
  background: white;
  padding: 1rem;
  border-radius: 8px;
  margin-top: 1rem;
}

.ai-summary h5 {
  color: var(--primary-text-color);
  margin-bottom: 0.5rem;
}

.reviews-section {
  background: white;
  padding: 1.5rem;
  border-radius: 8px;
  margin-top: 1.5rem;
}

.reviews-content {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.score-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.star-rating {
  display: flex;
  gap: 0.25rem;
}

.star-icon {
  width: 20px;
  height: 20px;
}

.star-rating .filled {
  color: var(--primary-text-color);
}

.star-rating .empty {
  color: var(--light-grey);
}

.reviews-content .vertical-divider {
  height: 6rem;
  margin: 0;
}

.review-summary {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.review-summary p {
  margin: 0;
  font-size: 0.9rem;
  color: var(--primary-text-color);
  line-height: 1.4;
}

.ai-note {
  font-size: 0.75rem;
  color: var(--grey);
}

.sales-bars {
  margin: 0;
  padding: 0px;
}

.sales-bar-container {
  height: 22px;
  background-color: var(--light-grey);
  border-radius: 10px;
  overflow: visible;
  position: relative;
  display: flex;
  margin: 10px 0;
}

.forecast-marker {
  position: absolute;
  top: -17px;
  transform: translateX(-50%);
  font-size: 0.85rem;
  font-weight: 500;
  color: var(--button-color);
  white-space: nowrap;
  z-index: 10;
}

.forecast-marker::after {
  content: '';
  position: absolute;
  left: 50%;
  top: 100%;
  height: 24px;
  width: 1.2px;
  background-color: currentColor;
  opacity: 0.4;
}

.forecast-marker.exceeded::after {
  background-color: var(--button-color);
  width: 1px;
  opacity: 0.8;
}

.sales-comparison {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: flex-start;
}

.comparison-indicator {
  font-size: 0.9rem;
  padding: 0.2rem 0.4rem;
  border-radius: 4px;
  font-weight: 400;
  margin-left: 0.5rem;
  background-color: var(--light-grey);
  display: inline-flex;
  align-items: center;
}

.sales-progress {
  height: 100%;
  transition: width 0.3s ease;
}

.sales-progress.main {
  z-index: 2;
  border-radius: 10px 0 0 10px;
  background-color: var(--success-green);
}

.sales-progress.main:last-child {
  border-radius: 10px;
}

.sales-progress.unpaid {
  opacity: 0.7;
  border-radius: 0 10px 10px 0;
  background-color: var(--error-red);
}

.sales-legend {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  margin-top: 8px;
}

.amount-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.75rem;
  width: 100%;
  min-height: 3.5rem;
}

.quick-gauge-value {
  font-size: 1.25rem;
  font-weight: bold;
  color: var(--primary-text-color);
  margin: 0;
}

.unpaid-note {
  font-size: 0.85rem;
  color: var(--error-red);
  font-weight: 500;
  margin: 0;
  align-self: flex-start;
}

.quick-gauge-subtitle {
  font-size: 1rem;
  color: var(--grey);
  margin: 0;
}

.key-metrics {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding: 1.5rem;
  background: white;
  border-radius: 8px;
}

.metric-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.metric-divider {
  width: 1px;
  height: 40px;
  background-color: var(--light-grey);
}

.gauge-grid {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.key-metrics {
  background: white;
  border-radius: 8px;
  padding: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.satisfaction-gauge {
  background: white;
  border-radius: 8px;
  padding: 1.5rem;
  text-align: center;
}

.metric-target {
  font-size: 0.4rem;
  color: var(--grey);
  margin-top: 0.25rem;
}

.business-details-button {
  text-decoration: none;
  display: inline-block;
}

.business-details-button :deep(.arrow-circle) {
  width: 3rem; 
  height: 3rem;
  margin-right: -0.75rem;
  background-color: var(--button-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.business-details-button :deep(.arrow-icon) {
  width: 20px;
  height: 20px;
  transition: filter 0.3s ease;
}

.business-details-button:hover :deep(.arrow-circle) {
  background-color: var(--primary-text-color);
  transform: translateX(2px);
}

.business-details-button:hover :deep(.arrow-icon) {
  filter: brightness(0) invert(1);
}

.latest-order {
  font-size: 0.85rem;
  color: var(--grey);
  margin: 0;
  text-align: left;
}

.quick-nav {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
  width: 100%;
}

.nav-block {
  background: white;
  padding: 1.5rem 1.5rem 3rem 1.5rem;
  border-radius: 8px;
  text-align: center;
  text-decoration: none;
  color: var(--primary-text-color);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.4rem;
  
}

.nav-block:not(.disabled):hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.nav-block i {
  font-size: 1.8rem;
  font-weight: 400;
  color: var(--primary-text-color);
  margin-bottom: 0.4rem;
}

.nav-block h3 {
  font-family: 'Darker Grotesque', sans-serif;
  color: var(--primary-text-color);
  font-weight: 500;
  font-size: 1.6rem;
  margin: 0;
}

.nav-block p {
  font-size: 1rem;
  color: var(--primary-text-color);
  margin: 0;
}

.nav-block.disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.coming-soon {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background: var(--grey);
  color: white;
  padding: 0.125rem 0.5rem;
  border-radius: 4px;
  font-size: 1rem;
  font-family: 'Darker Grotesque', sans-serif;
}

.section-title-pill {
  display: inline-block;
  padding: 10px 30px;
  border: 1px solid var(--primary-text-color);
  border-radius: 100px;
  font-size: 14px;  
  font-weight: 400;
  letter-spacing: 0.12em;
  margin-bottom: 2rem;
  align-self: flex-start;
  color: var(--primary-text-color);
}

.bottom-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 1rem;
}

.dashboard-header {
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  cursor: pointer;
}

.dashboard-header h2 {
  font-family: 'Darker Grotesque', sans-serif;
  font-size: 2rem;
  font-weight: 400;
  color: var(--primary-text-color);
  margin: 0;
  width: 7rem;
}

.vertical-divider {
  width: 1px;
  height: 2.5rem;
  background-color: var(--primary-text-color);
  margin: 0 2rem;
  flex-shrink: 0;
}

.basic-metrics {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.expand-indicator {
  margin-left: auto;
  transition: transform 0.3s ease;
}

.expand-indicator.expanded {
  transform: rotate(180deg);
}

.expand-indicator i {
  font-size: 1rem;
  color: var(--grey);
}

.expanded-content {
  padding: 0 1rem 1rem;
}

.horizontal-divider {
  width: 100%;
  height: 1px;
  background-color: var(--primary-text-color);
  margin: 1rem 0 2rem;
}

.metrics-grid {
  display: grid;
  grid-template-columns: 2fr 4.3fr;
  gap: 2rem;
  margin-bottom: 2rem;
}

.metrics-section {
  background: white;
  padding: 0rem 1.5rem 0rem 0rem;
  border-radius: 8px;
}

.metrics-section h4 {
  font-family: 'Darker Grotesque', sans-serif;
  font-size: 1.3rem;
  font-weight: 400;
  letter-spacing: 0.05em;
  margin: 0 0 1rem 0;
  color: var(--primary-text-color);
}

.metrics-content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.metric-group {
  display: flex;
  flex-direction: column;
  gap: 0rem;
}

.metric-label {
  font-size: 1rem;
  font-weight: 500;
  color: var(--primary-text-color);
}

.metric-value {
  font-size: 2rem;
  font-weight: 500;
  margin: -0.75rem 0 0 0;
}

.salary-metric {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.metric-target {
  font-size: 0.9rem;
  color: var(--grey);
  font-weight: 400;
}

.reviews-content {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.review-score {
  font-size: 3.5rem;
  font-weight: 600;
  color: var(--primary-text-color);
}

.review-summary {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.review-summary p {
  margin: 0;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.2;
  color: var(--primary-text-color);
}

.ai-note {
  font-size: 1rem;
  color: var(--primary-text-color);
}

.sales-chart {
  background: var(--background-color);
  opacity: 0.8;
  border-radius: 8px;
  margin-top: 1rem;
}

.sales-chart h4 {
  font-family: 'Darker Grotesque', sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  margin: 0 0 1.5rem 0;
  color: var(--primary-text-color);
  text-transform: uppercase;
}

.chart {
  height: 300px;
  width: 100%;
}

.salary-row {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.salary-box {
  font-size: 1.75rem;
  font-weight: 700;
  padding: 0.2rem 0.3rem;
  border-radius: 4px;
}

.metric-target {
  font-size: 1rem;
  color: var(--primary-text-color);
  font-weight: 500;
}
</style>
