<template>
  <footer class="footer">
    <div class="footer-content">
      <img src="@/assets/images/FullLogo.svg" alt="Databite Logo" class="footer-logo">
      <div class="footer-divider"></div>
      <div class="footer-bottom">
        <div class="footer-address">
          <div>Langelandsgade 121, 1</div>
          <div>8000 Aarhus C</div>
          <div>Danmark</div>
        </div>
        <div class="footer-copyright">Databite &copy;</div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'SiteFooter'
}
</script>

<style scoped>
.footer {
  margin-top: 8rem;
  padding: 6rem 1rem;
  background-color: var(--background-color);
}

.footer-content {
  max-width: 1400px;
  margin: 0 auto;
}

.footer-logo {
  width: 450px;
  height: auto;
  display: block;
  margin: 0 auto 4rem auto;
  margin-left: 0;
}

.footer-divider {
  height: 1px;
  background-color: var(--primary-text-color);
  margin-bottom: 3rem;
}

.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 0.9rem;
}

.footer-address {
  line-height: 1.6;
}

@media (max-width: 768px) {
  .footer {
    padding: 4rem 1rem;
  }

  .footer-logo {
    width: 100%;
    max-width: 300px;
  }
  
  .footer-bottom {
    flex-direction: column;
    gap: 2rem;
    text-align: center;
  }
}
</style>