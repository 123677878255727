<template>
  <div class="contact-form-container">
    <div class="form-wrapper">
      <h1>Kontakt os</h1>
      <p class="subtitle">Udfyld formularen nedenfor, så vender vi tilbage hurtigst muligt</p>

      <form @submit.prevent="handleSubmit" class="contact-form">
        <div class="form-group">
          <label for="email">Emailadresse</label>
          <input 
            type="email" 
            id="email" 
            v-model="formData.email" 
            required
            placeholder="Indtast din email"
          >
        </div>

        <div class="form-group">
          <label for="company">Virksomhedsnavn</label>
          <input 
            type="text" 
            id="company" 
            v-model="formData.company" 
            placeholder="Virksomhedsnavn"
          >
        </div>

        <div class="form-group">
          <label for="subject">Emne</label>
          <input 
            type="text" 
            id="subject" 
            v-model="formData.subject" 
            required
            placeholder="Hvad drejer din henvendelse sig om?"
          >
        </div>

        <div class="form-group">
          <label for="message">Besked</label>
          <textarea 
            id="message" 
            v-model="formData.message" 
            rows="5" 
            required
            placeholder="Skriv din besked her..."
          ></textarea>
        </div>

        <div v-if="error" class="error-message">
          {{ error }}
        </div>

        <div v-if="success" class="success-message">
          {{ success }}
        </div>

        <GreenButton 
          type="submit" 
          :disabled="isSubmitting"
          class="submit-button"
        >
          {{ isSubmitting ? 'Sender...' : 'Send besked' }}
        </GreenButton>
      </form>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { getApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';
import GreenButton from './GreenButton.vue';
import { useRoute } from 'vue-router';

const app = getApp(); 

export default {
  name: 'ContactForm',
  components: {
    GreenButton
  },
  setup() {
    const route = useRoute();
    const formData = ref({
      email: '',
      company: '',
      subject: '',
      message: ''
    });
    const error = ref('');
    const success = ref('');
    const isSubmitting = ref(false);

    onMounted(() => {
      if (route.query.subject) {
        formData.value.subject = route.query.subject;
      }
      if (route.query.email) {
        formData.value.email = route.query.email;
      }
    });

    const handleSubmit = async () => {
      error.value = '';
      success.value = '';
      isSubmitting.value = true;

      try {
        console.log('Submitting form with data:', formData.value);
        const functions = getFunctions(app, 'europe-west1');
        const sendContactEmail = httpsCallable(functions, 'sendContactEmail');
        
        const result = await sendContactEmail({
          email: formData.value.email,
          subject: formData.value.subject,
          message: formData.value.message
        });

        console.log('Response from function:', result);

        success.value = 'Tak for din henvendelse! Vi vender tilbage hurtigst muligt.';
        formData.value = {
          email: '',
          subject: '',
          message: ''
        };
      } catch (err) {
        console.error('Detailed error:', {
          error: err,
          code: err.code,
          message: err.message,
          details: err.details
        });
        error.value = 'Der opstod en fejl. Prøv venligst igen senere.';
      } finally {
        isSubmitting.value = false;
      }
    };

    return {
      formData,
      error,
      success,
      isSubmitting,
      handleSubmit
    };
  }
};
</script>

<style scoped>
.contact-form-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
}

.form-wrapper {
  background: white;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 2px 4px var(--shadow-color);
}

h1 {
  color: var(--primary-text-color);
  margin-bottom: 0.5rem;
  font-family: 'Questrial', sans-serif;
}

.subtitle {
  color: var(--secondary-text-color);
  margin-bottom: 2rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  color: var(--primary-text-color);
}

input, textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-family: 'Questrial', sans-serif;
  font-size: 0.9rem;
}

textarea {
  resize: vertical;
}

.error-message {
  color: red;
  margin-bottom: 1rem;
}

.success-message {
  color: var(--success-green);
  margin-bottom: 1rem;
}

.submit-button {
  width: auto;
  min-width: 200px;
  height: 48px;
  margin-top: 1rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.submit-button:hover {
  color: white;
}

@media (max-width: 768px) {
  .contact-form-container {
    padding: 1rem;
    width: 100%;
    box-sizing: border-box;
  }
  
  .form-wrapper {
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 1px 3px var(--shadow-color);
  }
  
  h1 {
    font-size: 1.8rem;
    text-align: center;
  }
  
  .subtitle {
    font-size: 0.9rem;
    text-align: center;
    margin-bottom: 1.5rem;
  }
  
  .form-group {
    margin-bottom: 1rem;
  }
  
  label {
    font-size: 0.9rem;
  }
  
  input, textarea {
    padding: 0.6rem;
    font-size: 1rem;
    border-radius: 4px;
  }
  
  textarea {
    min-height: 100px;
  }
  
  .submit-button {
    width: 100%;
    margin-top: 1.5rem;
    height: 44px;
  }
  
  .error-message, .success-message {
    font-size: 0.9rem;
    padding: 0.5rem;
    border-radius: 4px;
    text-align: center;
  }
  
  .error-message {
    background-color: rgba(255, 0, 0, 0.1);
  }
  
  .success-message {
    background-color: rgba(0, 128, 0, 0.1);
  }
}
</style> 