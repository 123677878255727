<template>
  <div class="settings_page">
    <h1>Indstillinger</h1>
    <p>Under udvikling.</p>
  </div>
</template>

<script>
export default {
  name: 'SettingsPage',
};
</script>

<style scoped>
.settings_page {
  padding: 2rem;
}
</style>
