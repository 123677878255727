<template>
  <div class="reset-password-container">
    <div class="reset-password-card">
      <img class="logo" src="@/assets/images/FullLogo.svg" alt="Databite Logo">
      
      <div v-if="error" class="error-message">
        {{ error }}
      </div>

      <div v-if="success" class="success-message">
        {{ success }}
        <router-link to="/login">Gå til login</router-link>
      </div>

      <form v-if="!success && !invalidLink" @submit.prevent="handlePasswordReset">
        <div class="form-group">
          <label for="newPassword">Ny adgangskode</label>
          <input 
            type="password" 
            id="newPassword" 
            v-model="newPassword"
            required
            minlength="6"
          >
        </div>
        
        <div class="form-group">
          <label for="confirmPassword">Bekræft ny adgangskode</label>
          <input 
            type="password" 
            id="confirmPassword" 
            v-model="confirmPassword"
            required
          >
        </div>

        <button 
          type="submit" 
          class="submit-button"
          :disabled="isLoading"
        >
          <span v-if="isLoading">Ændrer adgangskode...</span>
          <span v-else>Skift adgangskode</span>
        </button>
      </form>

      <!-- New section for requesting new link -->
      <div v-if="invalidLink" class="request-new-link">
        <p>Du kan anmode om et nyt link til nulstilling af adgangskode</p>
        <form @submit.prevent="handleRequestNewLink">
          <div class="form-group">
            <input 
              type="email" 
              id="email" 
              v-model="email"
              required
              placeholder="Indtast din email"
            >
          </div>

          <button 
            type="submit" 
            class="submit-button"
            :disabled="isRequestingNewLink"
          >
            <span v-if="isRequestingNewLink">Sender link...</span>
            <span v-else>Anmod om nyt link</span>
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { getAuth, confirmPasswordReset, verifyPasswordResetCode, sendPasswordResetEmail } from 'firebase/auth';

export default {
  name: 'ResetPassword',
  props: {
    oobCode: {
      type: String,
      required: true
    },
    mode: {
      type: String,
      required: true
    }
  },

  setup(props) {
    const newPassword = ref('');
    const confirmPassword = ref('');
    const email = ref('');
    const error = ref('');
    const success = ref('');
    const isLoading = ref(false);
    const isRequestingNewLink = ref(false);
    const invalidLink = ref(false);
    const auth = getAuth();

    onMounted(async () => {
      try {
        await verifyPasswordResetCode(auth, props.oobCode);
      } catch (err) {
        error.value = 'Dette link er ugyldigt eller udløbet.';
        invalidLink.value = true;
      }
    });

    const handleRequestNewLink = async () => {
      if (!email.value) return;
      
      isRequestingNewLink.value = true;
      error.value = '';

      try {
        await sendPasswordResetEmail(auth, email.value);
        success.value = 'Et nyt link til nulstilling af adgangskode er blevet sendt til din email';
        email.value = '';
      } catch (err) {
        error.value = 'Der opstod en fejl. Kontroller at email-adressen er korrekt';
      } finally {
        isRequestingNewLink.value = false;
      }
    };

    const handlePasswordReset = async () => {
      if (newPassword.value !== confirmPassword.value) {
        error.value = 'De nye adgangskoder matcher ikke';
        return;
      }

      isLoading.value = true;
      error.value = '';

      try {
        await confirmPasswordReset(auth, props.oobCode, newPassword.value);
        success.value = 'Din adgangskode er blevet ændret. Du kan nu logge ind med din nye adgangskode.';
        newPassword.value = '';
        confirmPassword.value = '';
      } catch (err) {
        error.value = 'Der opstod en fejl ved nulstilling af adgangskoden. Prøv igen senere.';
      } finally {
        isLoading.value = false;
      }
    };

    return {
      newPassword,
      confirmPassword,
      email,
      error,
      success,
      isLoading,
      isRequestingNewLink,
      invalidLink,
      handlePasswordReset,
      handleRequestNewLink
    };
  }
};
</script>

<style scoped>
.reset-password-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: var(--background-color);
  padding: 1rem;
}

.reset-password-card {
  background: var(--white);
  padding: 2rem;
  border-radius: 8px;
  width: 100%;
  max-width: 400px;
  box-shadow: 0 2px 4px var(--shadow-color);
}

.logo {
  width: 120px;
  margin: 0 auto 2rem;
  display: block;
}

.form-group {
  margin-bottom: 1.5rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  color: var(--primary-text-color);
}

input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid var(--input-border);
  border-radius: 4px;
}

.error-message {
  color: var(--error-red);
  margin-bottom: 1rem;
  text-align: center;
}

.success-message {
  color: var(--success-green);
  margin-bottom: 1rem;
  text-align: center;
}

.submit-button {
  width: 100%;
  padding: 0.75rem;
  background-color: var(--button-color);
  color: var(--button-text-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.request-new-link {
  text-align: center;
  margin-top: 1rem;
}

.request-new-link p {
  margin-bottom: 1rem;
  color: var(--primary-text-color);
}

.request-new-link form {
  max-width: 300px;
  margin: 0 auto;
}
</style>
